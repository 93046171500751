import { useEffect, useState } from 'react';
import Images from "../assets/images/index";
import { validateNotEmpty, validateEmailFormat } from '../utils/validation';
import { useSendVerificationCode } from '../services/user.service';
import IResponse from '../types/response';
import { storage } from '../utils/storage';
import { isNotNullUndefinedBlank, isUserStatusHasPermission, statusWiseOnboardingRedirectToPage } from '../helpers/common';
import { useNavigate } from 'react-router-dom';
import * as Sentry from "@sentry/react";

const Forgotpassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const codeType = isNotNullUndefinedBlank(email) ? "email" : "";
    const [emailError, setEmailError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [formResponseError, setFormResponseError] = useState('');

    const checkValidation = () => {
        let isValid = true;
        setFormResponseError("");
        if (!validateNotEmpty(email)) {
            setEmailError('Email is required');
            isValid = false;
        } else if (!validateEmailFormat(email)) {
            setEmailError('Invalid email format');
            isValid = false;
        } else {
            setEmailError('');
        }
        return isValid;
    };

    useEffect(() => {
        if (!isUserStatusHasPermission(1)) {
            navigate(statusWiseOnboardingRedirectToPage());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendVerificationCode = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setEmailError('');
        if (checkValidation()) {
            sendVerificationCodeMutation.mutate({ email: email, codeType: codeType });
        }
    };

    const sendVerificationCodeMutation = useSendVerificationCode({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                storage.setEmailId(email);
                storage.setCodeType(codeType);
                navigate('/forgotpassword/verify');
            }
            else {
                setIsLoading(false);
                setFormResponseError(response.statusMessage);
            }
        },
        onError: (error) => {
            setIsLoading(false);
            Sentry.captureException(error);
            setFormResponseError(error.message);
        }
    });

    return (
        <div className="bg-black-russian">
            <div className="grid grid-cols-1 lg:grid-cols-2 mx-auto max-h-full min-h-screen">
                <div className='hidden lg:block'>
                    <div className="left mx-auto w-full xl:p-0 bg-pattern-1 bg-cover bg-no-repeat bg-center h-full">
                        <div className='flex justify-center'>
                            <img
                                className="mt-[75px] lg:w-56 w-36 sm:w-48 flex"
                                src={Images.ailtralogo}
                                alt="Ailtra Logo" draggable="false" />
                        </div>
                        <div className='flex justify-center items-center'>
                            <div className='w-[562px]'>
                                <div className='flex justify-center my-8 lg:my-[100px]'>
                                    <img
                                        className="bg-contain w-1/2 lg:w-4/5 3xl:w-4/6 bg-no-repeat"
                                        src={Images.forgotpasswordlogo}
                                        alt="welcome Logo" draggable="false" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right bg-white rounded-t-none p-4 lg:p-0 lg:rounded-tl-3xl lg:rounded-bl-3xl lg:rounded-tr-none h-full">
                    <div className='w-full flex justify-center'>
                        <div className='w-36 h-1/5'>
                            <img
                                className="w-full mt-4 lg:hidden block"
                                src={Images.mobilelogo}
                                alt="Mobile Logo" draggable="false" />
                        </div>
                    </div>
                    <div className='flex justify-center items-center mt-8 lg:mt-0 lg:h-full'>
                        <div className='w-[340px] xl:w-[428px]'>
                            <h1 className="text-xl md:text-2xl xl:text-[34px] font-semibold tracking-normal subpixel-antialiased">
                                Forgot Your Password?
                            </h1>
                            <p className="lg:text-15 text-sm font-normal text-blue-gulf sm:leading-6 lg:mt-7 tracking-0.16 mt-3">
                                Enter your email below to receive a password reset link.
                            </p>
                            <form onSubmit={sendVerificationCode}>
                                <div className="mt-6 sm:mt-9 2xl:mt-12">
                                    <label className={`block relative border px-4 py-2 bg-blue-alice focus:border-hover cursor:text transition-all duration-600
                                                        ${emailError ? 'border-red-500 hover:border-red-500' : 'border-blue-hawkes focus:border-blue-rock hover:border-hover'} `}>
                                        <span className="block text-xs font-semibold text-black-pearl">Email</span>
                                        <input
                                            type="email"
                                            name="email"
                                            className="pt-1 placeholder-grey-storm bg-blue-alice focus:outline-none text-sm w-full"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            onBlur={() => checkValidation()}
                                            placeholder="Enter your email Address"
                                        />
                                        {emailError && <p className="text-red-500 text-xs font-semibold mt-1 absolute -bottom-5 -ml-4">{emailError}</p>}
                                    </label>
                                    <p className="text-red-500 text-xs font-semibold mt-1 absolute">{formResponseError}</p>
                                    <button
                                        type="submit"
                                        disabled={isLoading}
                                        className={`flex items-center justify-center relative w-full py-3.5 cursor-pointer font-bold text-sm transition-all text-white mt-4 sm:mt-7 2xl:mt-8.5 btn-hover-animation focus-visible:bg-[right_center] hover:bg-[right_center] bg-gradient-hover outline-none ${isLoading ? 'bg-gradient-hover opacity-60' : 'bg-gradient-hover'}`} >
                                        {isLoading ? (
                                            <>
                                                <p className='w-4 h-4 border-2 border-dashed animate-spin rounded-full mr-2'>
                                                </p>
                                                Please wait...
                                            </>
                                        ) : (
                                            'RESET PASSWORD'
                                        )}
                                    </button>
                                </div>
                            </form>
                            <div className="mt-6 sm:mt-9 2xl:mt-12">
                                <p className="text-base font-normal text-center text-black-russian">Don't have an account?<span className="text-base font-semibold">
                                    <a href="/registration" className="outline-none cursor-pointer"> <span className="text-xs sm:text-base font-semibold underline outline-none">Create a new one!</span></a>
                                </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Forgotpassword;