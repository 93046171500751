import { useEffect, useState } from "react";
import Images from '../assets/images';
import AssetMarketWatch from "./assetmarketwatch";
import { useAssetMarketWatchData } from '../helpers/datacontext';
import { formatNumberToFixed } from "../helpers/commaformattednumber";
import { useNavigate } from "react-router-dom";
import { storage } from "../utils/storage";
import useRenewAccessToken, { isNotNullUndefined, isNotNullUndefinedBlank, isTokenValid } from "../helpers/common";
import { useFetchUser } from "../services/user.service";
import IResponse from "../types/response";
import * as Sentry from "@sentry/react";

// Function and variable to set the button type and dispatch an event
let backTestHeaderType = "";
export const setBackTestButtonType = (type: string) => {
    backTestHeaderType = type;
    window.dispatchEvent(new Event('backTestButtonTypeUpdated'));
};
// Function to get the current button type
const getBackTestButtonType = () => backTestHeaderType;

const Header = () => {
    const { currentAsset } = useAssetMarketWatchData();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const navigate = useNavigate();
    const renewAccessToken = useRenewAccessToken();
    const [headerType, setHeaderType] = useState(getBackTestButtonType());

    useEffect(() => {
        const onHeaderTypeChange = () => {
            // Update local state to trigger re-render
            setHeaderType(getBackTestButtonType());
        };
        // Listen for the custom event
        window.addEventListener('backTestButtonTypeUpdated', onHeaderTypeChange);
        // Clean up the event listener when component unmounts
        return () => { window.removeEventListener('backTestButtonTypeUpdated', onHeaderTypeChange); };
    }, []);

    useEffect(() => {
        fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleModeChange = () => {
        setIsDarkMode(!isDarkMode);
    };

    const logout = () => {
        window.localStorage.clear();
        navigate("/");
    }

    const fetchUser = async () => {
        if (isNotNullUndefinedBlank(storage.getToken()) && isNotNullUndefinedBlank(storage.getRefreshToken())) {
            fetchUserMutation.mutate({});
        }
    };

    const fetchUserMutation = useFetchUser({
        onSuccess: async (response: IResponse) => {
            if (response.statusCode === 200 && isNotNullUndefined(response.data)) {
                storage.setUserStatusId(response.data.statusId);
                storage.setUserRoleId(response.data.userRoleId);
            }
            if (!isTokenValid(response)) {
                let tokenRenewed = await renewAccessToken();
                if (isNotNullUndefined(tokenRenewed) && tokenRenewed.statusCode === 200) {
                    fetchUser();
                }
            }
            else {
                console.error(response.statusMessage);
            }
        },
        onError: (error) => {
            Sentry.captureException(error);
            console.error(error.message);
        }
    });


    return (
        <header className="header bg-grey-bunker shadow px-4 md:ml-3 sticky top-0 z-10">
            <div className="header-content flex items-center justify-end md:justify-between">

                <form action="#">
                    <div className="relative my-2">
                        {headerType === 'overview' ? (
                            <button onClick={() => { navigate('/backtest/history'); }}
                                className='rounded-lg py-2 w-44 px-2 btn-hover-animation hover:bg-[right_center] bg-gradient-hover'>
                                <span className='text-sm font-semibold text-white flex justify-center items-center gap-2'>
                                    <img src={Images.whitearrowleft} alt="back-arrow" title="Back To BackTest History"/>
                                    BackTest History
                                </span>
                            </button>
                        ) : null}
                    </div>
                    <div className="relative my-2 hidden">
                        <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-5 xl:w-10 mx-3 xl:mx-0">
                            <img src={Images.magnifer} alt="Magnifer" />
                        </div>
                        <input
                            id="search"
                            type="text"
                            name="search"
                            className="text-sm xl:text-base text-white font-normal pl-10 pr-4 bg-grey-ebony-Clay w-52 lg:w-64 2xl:w-80 h-11 focus:outline-none"
                            placeholder="Search here"
                        />
                    </div>
                </form>
                <div className="flex items-center gap-3 xl:pr-6">
                    <div className="flex justify-between items-center min-w-[210px]  sm:min-w-[260px] xl:min-w-[395px] reletive group cursor-pointer bg-black-russian py-2 px-3 xl:py-2.5 xl:px-4 rounded-full my-2">
                        <div className="flex items-center mr-4 xl:mr-6">
                            <div className="w-2 h-2 xl:w-3 xl:h-3 mr-2 flex items-center">
                                <img src={Images.coinarrow} alt="" />
                            </div>
                            <div className="bg-grey-tuna w-8 h-8 xl:w-10 xl:h-10 flex justify-center items-center rounded-full">
                                <div className="w-7 h-7">
                                    <img
                                        src={currentAsset?.assetLogoUrl}
                                        className="w-full rounded-full"
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="ml-3 hidden xl:block">
                                <h2 className="text-15 text-white font-semibold tracking-normal">
                                    {currentAsset?.baseSymbol}
                                </h2>
                                <p className="text-xs text-white font-normal tracking-normal">
                                    {currentAsset?.assetName}
                                </p>
                            </div>
                        </div>
                        <AssetMarketWatch></AssetMarketWatch>
                        <div>
                            <h2 className="text-13 text-white font-semibold tracking-normal block xl:hidden">
                                {currentAsset?.baseSymbol}
                            </h2>
                            <p className="text-xs text-white font-normal hidden xl:block">Price</p>
                            <div className="flex justify-between items-center">
                                <h2 className={`text-sm ${(currentAsset?.lastPrice ?? 0) >= (currentAsset?.oldLastPrice ?? 0) ? 'text-emerald' : 'text-persian'} xl:text-lg font-semibold tracking-normal`}>
                                    {currentAsset?.lastPrice ?? 0}
                                </h2>
                                <span className={`${(currentAsset?.percentage ?? 0) >= 0 ? 'bg-emerald' : 'bg-persian'} text-white text-10 rounded-full py-0.5 xl:py-1 px-1 xl:px-2 ml-2.5`}>
                                    {formatNumberToFixed((currentAsset?.percentage ?? 0), 2)}%
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="hidden md:contents">
                        <div className="">
                            <img className=" w-5 xl:w-6" src={Images.bellbing} alt="Bellbing" />
                        </div>
                        <div className="flex cursor-pointer">
                            <button onClick={handleModeChange} className={`sun ${isDarkMode ? "bg-gradient w-[42px] h-8.5 px-2 py-1.5 flex justify-center" : "bg-grey-ebony-Clay opacity-50 hidden lg:block w-[42px] h-8.5 px-2 py-1.5"} `}>
                                <img className="" src={Images.sun} alt="Sun" />
                            </button>
                            <button onClick={handleModeChange} className={`moon ${isDarkMode ? "bg-grey-ebony-Clay opacity-50 hidden lg:block w-[42px] h-8.5 px-2 py-1.5" : "bg-gradient w-[42px] h-8.5 px-2 py-1.5 flex justify-center"}`}>
                                <img className="" src={Images.moon} alt="Moon" />
                            </button>
                        </div>
                    </div>
                    <div className="relative ">
                        <button type="button" className="relative flex items-center bg-black-russian rounded-full" onClick={toggleMenu}>
                            <svg className="w-10 xl:w-12" viewBox="0 0 52 50" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <path
                                    d="M48.9793 30.5C50.9442 27.0966 50.9442 22.9034 48.9793 19.5L42.2528 7.84936C40.2878 4.44595 36.6564 2.34936 32.7265 2.34936L19.2735 2.34936C15.3436 2.34936 11.7122 4.44595 9.74723 7.84936L3.02073 19.5C1.05577 22.9034 1.05577 27.0966 3.02073 30.5L9.74722 42.1506C11.7122 45.554 15.3436 47.6506 19.2735 47.6506L32.7265 47.6506C36.6564 47.6506 40.2878 45.554 42.2528 42.1506L48.9793 30.5Z"
                                    stroke="white" strokeWidth="2" />
                                <mask id="mask0_3700_434" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="2" y="3" width="48" height="44">
                                    <path
                                        d="M48.1132 20C49.8996 23.094 49.8996 26.906 48.1132 30L41.3868 41.6506C39.6004 44.7446 36.2992 46.6506 32.7265 46.6506L19.2735 46.6506C15.7008 46.6506 12.3996 44.7446 10.6132 41.6506L3.88676 30C2.10042 26.906 2.10042 23.094 3.88676 20L10.6133 8.34936C12.3996 5.25535 15.7009 3.34936 19.2735 3.34936L32.7265 3.34936C36.2992 3.34936 39.6004 5.25535 41.3868 8.34936L48.1132 20Z"
                                        fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_3700_434)">
                                    <image
                                        x="0" // Set the X and Y coordinates to position the image
                                        y="0"
                                        width="52" // Set the width and height to match the image's dimensions
                                        height="50"
                                        xlinkHref={Images.loginprofile} // Replace with the actual image URL
                                    />
                                </g>
                            </svg>

                            <div className="hidden xl:block">
                                <div className="bg-black-russian rounded-r-full ml-2.5 mr-4 xl:flex items-center gap-2.5">
                                    <span className="text-white font-semibold capitalize">
                                        {((storage.getUserFullName() ?? "") !== "") ? storage.getUserFullName() : (((storage.getEmailId() ?? "") !== "") ? (storage.getEmailId())?.split('@')[0] : "User Name")}
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path
                                            d="M11.9465 5.45331H7.79316H4.05317C3.41317 5.45331 3.09317 6.22664 3.5465 6.67997L6.99983 10.1333C7.55317 10.6866 8.45317 10.6866 9.0065 10.1333L10.3198 8.81997L12.4598 6.67997C12.9065 6.22664 12.5865 5.45331 11.9465 5.45331Z"
                                            fill="white" />
                                    </svg>
                                </div>
                            </div>
                        </button>
                        <div id="menuDropdown"
                            className={`absolute right-0 z-10 mt-2 px-3.5 py-2 w-48 origin-top-right rounded-md bg-black-russian border-image-radius shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${isMenuOpen ? "block" : "hidden"}`}
                            role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" transition-enter="transition ease-out duration-100 transform"
                            transition-enter-start="opacity-0 scale-95" transition-enter-end="opacity-100 scale-100"
                            transition-exit="transition ease-in duration-75 transform" transition-exit-start="opacity-100 scale-100"
                            transition-exit-end="opacity-0 scale-95">
                            <button type="button" className="flex py-2 px-1.5 text-sm font-semibold text-white hover:bg-grey-ebony-Clay hover:rounded-md border-b border-grey-ebony" role="menuitem">
                                <span className="h-5 w-5 mr-2.5"><img alt="User Settings" src={Images.usersetting}></img></span>Account Setting
                            </button>
                            <button type="button" className="flex py-2 px-1.5 text-sm font-semibold text-white hover:bg-grey-ebony-Clay hover:rounded-md border-b border-grey-ebony" role="menuitem">
                                <span className="h-5 w-5 mr-2.5"><img alt="Support" src={Images.support}></img></span>Get Support
                            </button>
                            <button type="button" onClick={logout} className="w-full flex py-2 px-1.5 text-sm font-semibold text-white hover:bg-grey-ebony-Clay hover:rounded-md" role="menuitem"><span className="h-5 w-5 mr-2.5"><img alt="" src={Images.logout}></img></span>Logout</button>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;