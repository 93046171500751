import { useEffect, useRef, useState } from 'react';
import Images from "../assets/images/index";
import { useNavigate } from "react-router-dom"
import { storage } from '../utils/storage';
import IResponse from '../types/response';
import { validateNotEmpty } from '../utils/validation';
import { useAddExchangeAccount, useUpdateUserExchangeAccountProcessStatus } from "../services/exchange.service";
import useRenewAccessToken, { isNotNullUndefinedBlank, statusWiseOnboardingRedirectToPage, isUserStatusHasPermission, isTokenValid, isNotNullUndefined } from '../helpers/common';
import * as Sentry from "@sentry/react";

const Binance = () => {

    const userExchangeStatusCode = useRef("");
    const userId = useRef("");

    const navigate = useNavigate();
    const [apiKey, setAPIKey] = useState('');
    const [secretKey, setSecretKey] = useState('');
    const [apiKeyError, setApiKeyError] = useState('');
    const [secretKeyError, setSecretKeyError] = useState('');
    const [formResponseError, setFormResponseError] = useState('');
    const [loading, setLoading] = useState(false)
    const renewAccessToken = useRenewAccessToken();

    const checkValidation = (field: string) => {
        let isValid = true;
        if (field === "apiKey" || field === "both") {
            if (!validateNotEmpty(apiKey)) {
                setApiKeyError('Please enter a valid api key');
                isValid = false;
            } else {
                setApiKeyError('');
            }
        }
        if (field === "secretKey" || field === "both") {

            if (!validateNotEmpty(secretKey)) {
                setSecretKeyError('Please enter a valid secret Key');
                isValid = false;
            } else {
                setSecretKeyError('');
            }
        }
        return isValid;
    };
    useEffect(() => {
        userId.current = storage.getUserId() ?? "";
        if (isUserStatusHasPermission(1) || isNotNullUndefinedBlank(storage.getUserExchangeStatusId())) {
            navigate(statusWiseOnboardingRedirectToPage());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const exchangeAccountMutation = useAddExchangeAccount({
        onSuccess: async (response: IResponse) => {
            if (response.statusCode === 200 || response.statusCode === 201) {
                userExchangeStatusCode.current = "ACCOUNT_ADDED";
                let userExchangeAccountId = response.data.userExchangeAccountId;
                storage.setUserExchangeAccountId(userExchangeAccountId);
                updateExchangeProcessStatusMutation.mutate({ "userExchangeAccountId": userExchangeAccountId, "statusCode": userExchangeStatusCode.current });
            }
            if (!isTokenValid(response)) {
                let tokenRenewed = await renewAccessToken();
                if (isNotNullUndefined(tokenRenewed) && tokenRenewed.statusCode === 200) {
                    exchangeAccountMutation.mutate({ exchangeId: 1, apiKey: apiKey, secretKey: secretKey, friendlyName: 'Binance' });
                }
            }
            else {
                setLoading(false);
                setFormResponseError(response.statusMessage);
            }
        },
        onError: (error) => {
            setLoading(false);
            Sentry.captureException(error);
            setFormResponseError(error.message);
        }
    });

    const onConnect = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkValidation('both')) {
            setLoading(true);
            exchangeAccountMutation.mutate({ exchangeId: 1, apiKey: apiKey, secretKey: secretKey, friendlyName: 'Binance' });
        }
    };

    const updateExchangeProcessStatusMutation: any = useUpdateUserExchangeAccountProcessStatus({
        onSuccess: async (response: IResponse) => {
            if (response.statusCode === 200) {
                if (userExchangeStatusCode.current === "ACCOUNT_ADDED") {
                    storage.setUserExchangeStatusId(10);
                    navigate('/account/asset/setup?id=' + (storage.getUserExchangeAccountId() ?? ""));
                }
            }
            if (!isTokenValid(response)) {
                setLoading(false);
                let tokenRenewed = await renewAccessToken();
                if (isNotNullUndefined(tokenRenewed) && tokenRenewed.statusCode === 200) {
                    updateExchangeProcessStatusMutation.mutate({ "userExchangeAccountId": storage.getUserExchangeAccountId(), "statusCode": userExchangeStatusCode.current });
                }
            }
            else {
                setLoading(false);
                setFormResponseError(response.statusMessage);
            }
        },
        onError: (error) => {
            setLoading(false);
            Sentry.captureException(error);
            setFormResponseError(error.message);
        }
    });

    return (
        <div className="bg-black-russian min-h-screen">
            <div className="grid grid-cols-1 lg:grid-cols-2 mx-auto max-h-full min-h-screen">
                <div className='hidden lg:block'>
                    <div className="left mx-auto w-full xl:p-0 bg-pattern-1 bg-cover bg-no-repeat bg-center h-full">
                        <div className='flex justify-center'>
                            <img
                                className="mt-[75px] lg:w-56 w-36 sm:w-48 flex"
                                src={Images.ailtralogo}
                                alt="Ailtra Logo" draggable="false" />
                        </div>
                        <div className='flex justify-center items-center'>
                            <div className='w-[562px]'>
                                <div className='flex justify-center my-8 lg:mt-[250px] lg:mb-[100px]'>
                                    <img
                                        className="bg-contain w-1/2 lg:w-[90%] bg-no-repeat"
                                        src={Images.binanceconected}
                                        draggable="false"
                                        alt="bitcoin gif" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right relative bg-white flex justify-center items-center rounded-t-none p-4 lg:p-0 lg:rounded-tl-3xl lg:rounded-bl-3xl lg:rounded-tr-none h-full">
                    <div className='w-[340px] 2xl:w-[388px]'>
                        <div>
                            <img
                                src={Images.Cloud}
                                draggable="false"
                                alt="Cloud"
                                className="absolute hidden lg:block w-24 md:w-44 lg:w-24 xl:w-40 3xl:w-52 top-0 left-0 md:top-0 md:left-0" />
                        </div>
                        <div className="flex justify-center mt-0 lg:mt-8 sm:mt-0">
                            <img src={Images.Binancelogopng} draggable="false" alt="Binance logo" className="w-44" />
                        </div>
                        <div className="mt-0 2xl:mt-8">
                            <p className="text-center text-sm 2xl:text-15 font-normal sm:leading-6 text-grey-storm mt-6">
                                To securely connect your Binance account and enable trading
                                functionality, please provide your API key and Secret key.
                            </p>
                            <p className="text-center text-sm 2xl:text-15 font-normal sm:leading-6 text-grey-storm mt-4 sm:mt-5">
                                These credentials will allow Ailtra to interact with your account
                                for executing trades. Don't worry, we prioritize the security of
                                your information. Watch the video tutorial below for step-by-step
                                instructions on how to generate your API keys.
                            </p>
                        </div>
                        <div className="my-6 flex justify-center align-middle h-40 lg:h-52">
                            <iframe
                                height="auto"
                                src="https://www.youtube.com/embed/9zOKftl70QA"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                className="w-full"
                            ></iframe>
                        </div>
                        <form onSubmit={onConnect}>
                            <label className={`block relative border-blue-hawkes px-4 p-2 bg-blue-alice border shadow-sm focus:outline-none focus:border-blue-rock focus:ring-blue-rock ${apiKeyError ? 'border-red-500 hover:border-red-500' : 'border-blue-hawkes focus:border-blue-rock hover:border-hover'}`}>
                                <span className="after:content-['*'] after:ml-0.5  after:text-red-500 block text-xs font-semibold text-slate-700">
                                    API key
                                </span>
                                <input
                                    type="text" name="key" value={apiKey} onChange={(e) => setAPIKey(e.target.value)}
                                    onBlur={() => checkValidation("apiKey")}
                                    className="placeholder-grey-storm  bg-blue-alice focus:outline-none text-sm w-full pt-2"
                                    placeholder="Enter you API key here" />
                            </label>
                            {apiKeyError && <p className="text-red-500 text-xs mt-0.5 font-semibold absolute">{apiKeyError}</p>}
                            <label className={`block relative border-blue-hawkes px-4 p-2 mt-6  bg-blue-alice border shadow-sm focus:outline-none focus:border-blue-rock focus:ring-blue-rock ${secretKeyError ? 'border-red-500 hover:border-red-500' : 'border-blue-hawkes focus:border-blue-rock hover:border-hover'}`}>
                                <span className="after:content-['*'] after:ml-0.5  after:text-red-500 block text-xs font-semibold text-slate-700">
                                    Secret key
                                </span>
                                <input
                                    type="text" name="key" value={secretKey} onChange={(e) => setSecretKey(e.target.value)}
                                    onBlur={() => checkValidation("secretKey")}
                                    className="placeholder-grey-storm  bg-blue-alice focus:outline-none text-sm w-full pt-2"
                                    placeholder="Enter your secret key here" />
                            </label>
                            {secretKeyError && <p className="text-red-500 text-xs mt-0.5 font-semibold absolute">{secretKeyError}</p>}
                            <div className="mt-8 relative">
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className={`w-full flex justify-center items-center py-3.5 font-bold text-sm transition-all text-white text btn-hover-animation focus-visible:bg-[right_center] hover:bg-[right_center] bg-gradient-hover outline-none ${loading ? 'bg-gradient-hover opacity-60' : 'bg-gradient-hover'}`} >
                                    {loading ? (
                                        <p className='flex items-center'>
                                            <span className='w-4 h-4 border-2 border-dashed animate-spin rounded-full mr-2'>
                                            </span>
                                            Connecting Securely...
                                        </p>
                                    ) : (
                                        'CONNECT SECURELY'
                                    )}
                                </button>
                                {formResponseError && <p className="text-red-500 text-xs font-semibold mt-1 absolute">{formResponseError}</p>}
                            </div>
                        </form>
                        <div className="mt-4 2xl:mt-9">
                            <p className="w-full flex justify-center" >
                                <a className="text-xs 2xl:text-base font-normal text-center text-black-russian underline"
                                    href='https://ailtra.ai/contact/'
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Need support?
                                </a>
                            </p>
                        </div>
                        <div className="flex align-middle">
                            <img
                                src={Images.plant}
                                draggable="false"
                                alt="Plant"
                                className="absolute hidden lg:block bottom-1 sm:bottom-0 right-5 w-16 md:w-32 lg:w-16 xl:w-28 3xl:w-36" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Binance;