import { useEffect, useState } from "react";
import Images from "../assets/images/index";
import { useNavigate } from "react-router";
import { isNotNullUndefinedBlank, statusWiseOnboardingRedirectToPage, isUserStatusHasPermission } from '../helpers/common';
import { storage } from "../utils/storage";

const Binanceconnect = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isUserStatusHasPermission(1) || isNotNullUndefinedBlank(storage.getUserExchangeStatusId())) {
            navigate(statusWiseOnboardingRedirectToPage());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onConnectBinance = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
            navigate('/connect/binance');
        },);

    };

    const logout = async () => {
        window.localStorage.clear();
        navigate('/');
    };

    return (
        <div className="bg-black-russian">
            <div className="grid grid-cols-1 lg:grid-cols-2 mx-auto max-h-full min-h-screen">
                <div className='hidden lg:block'>
                    <div className="left mx-auto w-full xl:p-0 bg-pattern-1 bg-cover bg-no-repeat bg-center h-full">
                        <div className='flex justify-center'>
                            <img
                                className="mt-[75px] lg:w-56 w-36 sm:w-48 flex"
                                src={Images.ailtralogo}
                                alt="Ailtra Logo" draggable="false" />
                        </div>
                        <div className='flex justify-center items-center'>
                            <div className='w-[562px]'>
                                <div className='flex justify-center my-8 lg:my-[100px]'>
                                    <img
                                        className="bg-contain w-[80%] xl:w-[90%] bg-no-repeat"
                                        src={Images.congratulation}
                                        alt="Congratulation Gif" draggable="false" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right relative bg-white flex justify-center items-center rounded-t-none p-4 lg:p-0 lg:rounded-tl-3xl lg:rounded-bl-3xl lg:rounded-tr-none h-full">
                    <div className='w-[340px] 2xl:w-[344px] lg:-mt-0'>
                        <div>
                            <img
                                src={Images.plan}
                                alt="Plan"
                                draggable="false"
                                className="absolute w-2/5 top-2 -left-6"
                            />
                        </div>
                        <div className="flex justify-center">
                            <img src={Images.emoji} draggable="false" alt="Emoji" />
                        </div>
                        <div className="mt-8">
                            <h4 className="text-2xl text-center font-bold text-black-pearl">
                                Congratulation!
                            </h4>
                            <p className="text-center text-sm md:text-15 font-normal sm:leading-6 text-grey-storm mt-6">
                                You've successfully created an account with Ailtra! To start
                                trading bot, let's connect your Binance account.
                            </p>
                        </div>
                        <div className="w-full flex justify-center">
                            <div className="flex justify-around align-middle mt-10 mb-10 w-[250px] md:w-[350px]">
                                <img src={Images.binanceconected} draggable="false" alt="Binance Connect" />
                            </div>
                        </div>
                        <div>
                            <p className="text-center text-sm md:text-15 font-normal sm:leading-6 text-grey-storm mt-6">
                                By connecting Binance, you'll be able to execute trades. Click the
                                button below to connect Binance and unlock the full potential of
                                Ailtra.
                            </p>
                        </div>
                        <form onSubmit={onConnectBinance}>
                            <div className="mt-9 3xl:mt-12">
                                <button
                                    type="submit"
                                    disabled={isLoading}
                                    className={`w-full flex items-center justify-center xl:mb-8 py-3.5 font-bold text-sm text-white btn-hover-animation focus-visible:bg-[right_center] hover:bg-[right_center] bg-gradient-hover outline-none ${isLoading ? 'bg-gradient-hover opacity-60' : 'bg-gradient-hover'}`} >
                                    {isLoading ? (
                                        <p className='flex items-center'>
                                            <span className='w-4 h-4 border-2 border-dashed animate-spin rounded-full mr-2'>
                                            </span>
                                            Connecting Binance...
                                        </p>
                                    ) : (
                                        'CONNECT BINANCE'
                                    )}
                                </button>
                                <div className="text-center flex items-center justify-center mb-32 mt-6" onClick={logout}>
                                    <button type="button" id="btnLogout" className="outline-none flex justify-center items-center"><img src={Images.logouticon} draggable="false" alt="Logout" className="mr-2"></img> <span className="text-base font-semibold underline">Log out</span></button>
                                </div>
                                <div className="flex justify-center align-middle">
                                    <img
                                        src={Images.avatar}
                                        draggable="false"
                                        alt="Avatar"
                                        className="absolute bottom-0 w-[300px] sm:w-[380px] 2xl:w-[493px]" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Binanceconnect;