import { useState, ChangeEvent, useEffect } from 'react';
import Images from "../assets/images/index";
import { validateNotEmpty } from '../utils/validation';
import { checkPasswordStrength } from '../helpers/passwordstrength';
import { useSetPassword } from '../services/user.service';
import { storage } from '../utils/storage';
import { useNavigate } from 'react-router-dom';
import { isUserStatusHasPermission, statusWiseOnboardingRedirectToPage } from '../helpers/common';
import * as Sentry from "@sentry/react";

const Setpassword = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isPasswordError, setIsPasswordError] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [isConfirmPasswordError, setIsConfirmPasswordError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordScore, setPasswordScore] = useState<number>(0);
    const [formResponseError, setFormResponseError] = useState('');

    const checkValidation = (field: string) => {
        let isValid = true;
        if (field === "password" || field === "both") {
            if (!validateNotEmpty(password)) {
                setPasswordError('Password is required');
                isValid = false;
                setIsPasswordError(true);
            } else if (password.length < 4) {
                setPasswordError('Password must be at least 4 characters long');
                isValid = false;
                setIsPasswordError(true);
            } else {
                setPasswordError('');
                setIsPasswordError(true);
            }
        }
        if (field === "confirmPassword" || field === "both") {
            if (!validateNotEmpty(confirmPassword)) {
                setConfirmPasswordError('Confirm Password is required');
                isValid = false;
                setIsConfirmPasswordError(true);
            } else if (confirmPassword !== password) {
                setConfirmPasswordError('Passwords do not match');
                isValid = false;
                setIsConfirmPasswordError(true);
            } else {
                setConfirmPasswordError('');
                setIsConfirmPasswordError(true);
            }
        }
        return isValid;
    };

    const inputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name === "password") {
            setPasswordScore(checkPasswordStrength(value));
        }
        setPassword(value);
    };

    const onSetPassword = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let param = {
            "userId": storage.getUserId(),
            "password": password
        }
        if (checkValidation("both")) {
            setIsLoading(true);
            setPasswordMutation.mutate(param);
        }
    };

    const setPasswordMutation = useSetPassword({
        onSuccess: (response) => {
            if (response.statusCode === 200) {
                window.localStorage.clear();
                navigate('/');
            }
            else {
                setIsLoading(false);
                setFormResponseError(response.statusMessage);
            }
        },
        onError: (error) => {
            setIsLoading(false);
            Sentry.captureException(error);
            setFormResponseError(error.message);
        }
    });

    useEffect(() => {
        if (!isUserStatusHasPermission(1)) {
            navigate(statusWiseOnboardingRedirectToPage());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // #region Password

    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const specialChars = '!@#$%^&*()-_=+[]{};:<>?';
    const allCharacters = [lowercase, uppercase, numbers, specialChars];
    const getRandomCharacter = (characters: any) => {
        const randomType = characters[Math.floor(Math.random() * characters.length)];
        const randomIndex = Math.floor(Math.random() * randomType.length);
        return randomType[randomIndex];
    };

    const onAutoGeneratePassword = () => {
        const password =
            getRandomCharacter(lowercase) +
            getRandomCharacter(uppercase) +
            getRandomCharacter(numbers) +
            getRandomCharacter(specialChars) +
            Array.from({ length: 4 }, () => getRandomCharacter(allCharacters)).join('');
        setPassword(password);
        setConfirmPassword(password);
        setPasswordScore(checkPasswordStrength(password));
    };

    const [showPassword, setShowPassword] = useState(false);
    const handlePasswordVisibility = () => {
        if (showPassword)
            setShowPassword(false);
        else if (!showPassword)
            setShowPassword(true);
    };

    const [showNewPassword, setShowNewPassword] = useState(false);
    const handleNewPasswordVisibility = () => {
        if (showNewPassword)
            setShowNewPassword(false);
        else if (!showNewPassword)
            setShowNewPassword(true);
    };

    //#endregion Password

    return (
        <div className="bg-black-russian">
            <div className="grid grid-cols-1 lg:grid-cols-2 mx-auto lg-block max-h-full min-h-screen">
                <div className='hidden lg:block'>
                    <div className="left mx-auto w-full xl:p-0 bg-pattern-1 bg-cover bg-no-repeat bg-center h-full">
                        <div className='flex justify-center'>
                            <img
                                className="mt-[75px] lg:w-56 w-36 sm:w-48 flex"
                                src={Images.ailtralogo}
                                alt="Ailtra Logo" draggable="false" />
                        </div>
                        <div className='flex justify-center items-center'>
                            <div className='w-[562px]'>
                                <div className='flex justify-center my-8 lg:my-[100px]'>
                                    <img
                                        className="bg-contain w-1/2 lg:w-4/5 3xl:w-4/6 bg-no-repeat"
                                        src={Images.resetpasswordlogo}
                                        alt="welcome Logo" draggable="false" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right bg-white rounded-t-none p-4 lg:p-0 lg:rounded-tl-3xl lg:rounded-bl-3xl lg:rounded-tr-none h-full">
                    <div className='w-full flex justify-center'>
                        <div className='w-36 h-1/5'>
                            <img
                                className="w-full mt-4 lg:hidden block"
                                src={Images.mobilelogo}
                                alt="Mobile Logo" draggable="false" />
                        </div>
                    </div>
                    <div className='flex justify-center items-center mt-8 lg:mt-0 lg:h-full'>
                        <div className='w-[340px] 2xl:w-[390px]'>
                            <div>
                                <h1 className="text-xl md:text-3xl xl:text-[34px] font-semibold subpixel-antialiased">Set your password</h1>
                                <p className="text-sm lg:text-15 font-normal text-blue-gulf sm:leading-6 tracking-0.16 mt-2 lg:mt-7"> Enter a new password for your account. </p>
                            </div>
                            <form onSubmit={onSetPassword}>
                                <div className="mt-6">
                                    <button type='button' className="mt-[45px] flex justify-end text-10 2xl:text-xs text-emerald underline mb-1 cursor-pointer select-none w-full outline-none" onClick={onAutoGeneratePassword}>
                                        Auto Generate Password
                                    </button>
                                    <label htmlFor="password" className={`flex justify-between items-center relative border-blue-hawkes bg-blue-alice border shadow-sm focus:outline-none focus:border-blue-rock focus:ring-blue-rock ${passwordError ? 'border-red-500 hover:border-red-500' : 'border-blue-hawkes focus:border-blue-rock hover:border-hover'}`}>
                                        <div className='w-full flex justify-between items-center px-4 py-2'>
                                            <div className='w-full'>
                                                <span className="block text-xs font-semibold text-black-pearl"> Password </span>
                                                {showPassword ? (
                                                    <input
                                                        type="text"
                                                        id="password"
                                                        name="password"
                                                        className="placeholder-grey-storm bg-blue-alice focus:outline-none text-sm w-full pt-1.5"
                                                        value={password}
                                                        onBlur={() => checkValidation("password")}
                                                        onChange={inputChange}
                                                        placeholder="Enter your password"
                                                    />
                                                ) : (
                                                    <input
                                                        type="password"
                                                        id="password"
                                                        name="password"
                                                        className="placeholder-grey-storm bg-blue-alice focus:outline-none text-sm w-full pt-1.5"
                                                        value={password}
                                                        onChange={inputChange}
                                                        onBlur={() => checkValidation("password")}
                                                        placeholder="Enter your password"
                                                        autoComplete="new-password"
                                                    />
                                                )}
                                            </div>
                                            <div className='flex items-center'>
                                                {showPassword ? (
                                                    <button type='button' className='outline-none' onClick={handlePasswordVisibility}>
                                                        <img
                                                            className="w-6 h-6 outline-none"
                                                            src={Images.eye}
                                                            alt="close-eye"
                                                        />
                                                    </button>
                                                ) : (
                                                    <button type='button' className='outline-none' onClick={handlePasswordVisibility}>
                                                        <img
                                                            className="w-6 h-6 outline-none"
                                                            src={Images.eyeopen}
                                                            alt="open-eye"
                                                        />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <div className='flex'>
                                            <div className={`p-5 ${passwordError ? 'bg-persian' : !isPasswordError ? 'hidden' : 'bg-emerald'} bg-opacity-25`}>
                                                <div className='w-5 h-5'>
                                                    <img
                                                        className="w-full"
                                                        src={passwordError ? Images.passwordwrong : Images.passwordright}
                                                        alt={passwordError ? "password-wrong" : "password-right"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                    <div className="flex -mx-1 mt-2">
                                        {Array.from({ length: 5 }).map((_, i) => (
                                            <div key={i} className="w-1/5 px-1">
                                                <div
                                                    className={`h-1 rounded-xl transition-colors ${i < passwordScore
                                                        ? passwordScore <= 2
                                                            ? "bg-fire-brick"
                                                            : passwordScore <= 2
                                                                ? "bg-burnt-sienna"
                                                                : passwordScore <= 4
                                                                    ? "bg-rob-roy"
                                                                    : "bg-kelly-green"
                                                        : "bg-blue-hawkes"
                                                        }`}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    {passwordError && <p className="text-red-500 text-xs font-semibold absolute mt-4.5">{passwordError}</p>}
                                    <div className="flex justify-between mt-1">
                                        <span className='text-10 2xl:text-xs font-light text-grey-storm'>
                                            Must be at least 4 character long
                                        </span>
                                        <span className={`text-10 font-semibold  ${passwordScore <= 2 ? 'text-red-500' : passwordScore <= 4 ? 'text-yellow-500' : 'text-gradient bg-gradient bg-clip-text'
                                            }`}>
                                            {passwordScore <= 2 ? 'Weak' : passwordScore <= 4 ? 'Medium' : 'Strong'}
                                        </span>
                                    </div>
                                    <label htmlFor="confirmPassword" className={`flex justify-between items-center mt-8 relative border-blue-hawkes bg-blue-alice border shadow-sm focus:outline-none focus:border-blue-rock focus:ring-blue-rock ${confirmPasswordError ? 'border-red-500 hover:border-red-500' : 'border-blue-hawkes focus:border-blue-rock hover:border-hover'}`}>
                                        <div className='w-full flex justify-between items-center px-4 py-2'>
                                            <div className='w-full'>
                                                <span className="block text-xs font-semibold text-black-pearl"> Confirm Password </span>
                                                {showNewPassword ? (
                                                    <input
                                                        type="text"
                                                        id="confirmPassword"
                                                        name="confirmPassword"
                                                        className="placeholder-grey-storm bg-blue-alice focus:outline-none text-sm w-full pt-1.5"
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                        onBlur={() => checkValidation("confirmPassword")}
                                                        placeholder="Enter your password"
                                                    />
                                                ) : (
                                                    <input
                                                        type="password"
                                                        id="confirmPassword"
                                                        name="confirmPassword"
                                                        className="placeholder-grey-storm bg-blue-alice focus:outline-none text-sm w-full pt-1.5"
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                        onBlur={() => checkValidation("confirmPassword")}
                                                        placeholder="Enter your password"
                                                        autoComplete="new-password"
                                                    />
                                                )}
                                            </div>
                                            <div className='flex items-center'>
                                                {showNewPassword ? (
                                                    <button type='button' className='outline-none' onClick={handleNewPasswordVisibility}>
                                                        <img
                                                            className="w-6 h-6 outline-none"
                                                            src={Images.eye}
                                                            alt="close-eye"
                                                        />
                                                    </button>
                                                ) : (
                                                    <button type='button' className='outline-none' onClick={handleNewPasswordVisibility}>
                                                        <img
                                                            className="w-6 h-6 outline-none"
                                                            src={Images.eyeopen}
                                                            alt="open-eye"
                                                        />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <div className='flex'>
                                            <div className='flex'>
                                                <div className={`p-5 ${confirmPasswordError ? 'bg-persian' : !isConfirmPasswordError ? 'hidden' : 'bg-emerald'} bg-opacity-25`}>
                                                    <div className='w-5 h-5'>
                                                        <img
                                                            className="w-full"
                                                            src={confirmPasswordError ? Images.passwordwrong : Images.passwordright}
                                                            alt={confirmPasswordError ? "password-wrong" : "password-right"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                    {confirmPasswordError && <p className="text-red-500 text-xs font-semibold absolute mt-1">{confirmPasswordError}</p>}
                                    <p className="text-red-500 text-xs font-semibold mt-1 absolute">{formResponseError}</p>
                                    <button
                                        type="submit"
                                        disabled={isLoading}
                                        className={`flex items-center justify-center relative w-full py-3.5 cursor-pointer font-bold text-sm transition-all text-white mt-4 sm:mt-7 2xl:mt-8.5 btn-hover-animation focus-visible:bg-[right_center] hover:bg-[right_center] bg-gradient-hover outline-none ${isLoading ? 'bg-gradient-hover opacity-60' : 'bg-gradient-hover'}`} >
                                        {isLoading ? (
                                            <>
                                                <p className='w-4 h-4 border-2 border-dashed animate-spin rounded-full mr-2'>
                                                </p>
                                                Logging in...
                                            </>
                                        ) : (
                                            'SET PASSWORD'
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Setpassword;