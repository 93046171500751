//#region Google Authenticate Url

const googleClientId = "797169647811-8q34g98ofbkc5a38cj21coip1t3drb9h.apps.googleusercontent.com";
const googleRedirectUrl = "https://acc.ailtra.ai/auth/google";

export function googleAuthenticateUrl(): any {
    return `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${googleClientId}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20openid%20profile%20email&openid.realm&redirect_uri=${googleRedirectUrl}&access_type=offline&prompt=consent`;
};

//#endregion Google Authenticate Url

//#region Facebook Authenticate Url

const facebookClientId = "671962921235467";
const facebookRedirectUrl = "https://acc.ailtra.ai/auth/facebook";

export function facebookAuthenticateUrl(): any {
    return `https://www.facebook.com/v17.0/dialog/oauth?client_id=${facebookClientId}&display=popup&response_type=token&redirect_uri=${facebookRedirectUrl}&scope=email`;
};

//#endregion Facebook Authenticate Url
