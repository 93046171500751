import { useEffect, useRef } from 'react';
import { Routes, Route } from "react-router-dom";
import Login from "./components/login";
import Registration from "./components/registration";
import Authorization from "./components/authorization";
import Binanceconnect from "./components/binanceconnect";
import Binance from "./components/binance";
import Consent from "./components/consent";
import Completeaccount from "./components/completeaccount";
import Googleloginredirect from "./components/googleloginredirect";
import FacebookLoginRedirect from "./components/facebookloginredirect";
import Setup from "./components/setup";
import User from "./components/user";
import CoinProfile from "./components/coinprofile";
import Coinaction from "./components/coinaction";
import Transactions from "./components/transactions";
import Dashboard from "./components/dashboard";
import UserProfile from "./components/userprofile";
import { useAssetMarketWatchData } from './helpers/datacontext';
import MainLayout from "./components/mainlayout";
import WebSocketManager, { MessageHandler } from './helpers/websocketmanager';
import { useGetExchangeAssets } from "./services/exchange.service";
import IResponse from "./types/response";
import Setpassword from "./components/setpassword";
import Forgotpassword from "./components/forgotpassword";
import ForgotPasswordVerify from './components/forgotpasswordverify';
import { handleError, isNotNullUndefinedBlank } from './helpers/common';
import { storage } from './utils/storage';
import BackTest from './components/backtesting';
import CompleteProfile from './components/completeprofile';
import BackTestHistory from './components/backtestinghistory';
import BackTestMainLayout from './components/backtestingmainlayout';

function App() {
  const { addMessage } = useAssetMarketWatchData();
  const url = useRef("");

  const getExchangeAssets = useGetExchangeAssets({
    onSuccess: async (response: IResponse) => {
      if (response.data !== null && response.data.length > 0) {
        //set asset details In Local storage
        const exchangeAssetsList = response;
        storage.setExchangeAssetsList(JSON.stringify(exchangeAssetsList));
        
        const streamValues = response.data.map((obj: any) => `${obj.exchangeSymbol.toLowerCase()}@aggTrade`);
        const concatenatedStreams = streamValues.join('/');
        const tickerValues = response.data.map((obj: any) => `${obj.exchangeSymbol.toLowerCase()}@ticker`);
        const tickerStreams = tickerValues.join('/');
        const baseUrl = response.data[0].streamUrl.substring(0, response.data[0].streamUrl.indexOf('?') + 1);
        const finalUrl = `${baseUrl}streams=${concatenatedStreams}/${tickerStreams}`;
        url.current = finalUrl;
        binanceWebsockets();
      }
    },
    onError: (error: any) => {
      handleError(error);
    },
  });

  useEffect(() => {
    if (isNotNullUndefinedBlank(storage.getToken())) {
      getExchangeAssets.mutate(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const binanceWebsockets = () => {
    const webSocketManager = new WebSocketManager();
    webSocketManager.connect("", url.current);
    const promise = async () => { webSocketManager.connect("", url.current); };
    const openSockets = async () => {
      const promises = [promise()];
      await Promise.all(promises);
      webSocketManager.addMessageHandler("", handleWebSocketMessage);
    };
    const handleWebSocketMessage: MessageHandler = (coin, message) => {
      if (message.data !== null && message.data !== undefined) {
        addMessage({ id: Date.now(), message: message });
      }
    };
    openSockets();
    return () => {
      webSocketManager.disconnect("");
    };
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="auth/google" element={<Googleloginredirect />} />
        <Route path="auth/facebook" element={<FacebookLoginRedirect />} />
        <Route path="registration" element={<Registration />} />
        <Route path="registration/verify" element={<Authorization />} />
        <Route path="connect/binance/info" element={<Binanceconnect />} />
        <Route path="connect/binance" element={<Binance />} />
        <Route path="connect/binance/consent" element={<Consent />} />
        <Route path="connect/binance/verify" element={<Completeaccount />} />
        <Route path="account/asset/setup" element={<MainLayout><Setup /></MainLayout>} />
        <Route path="account/asset/:symbol" element={<MainLayout><CoinProfile /></MainLayout>} />
        <Route path="account/asset/:symbol/setup" element={<MainLayout><Coinaction /></MainLayout>} />
        <Route path="user" element={<MainLayout><User /></MainLayout>} />
        <Route path="transactions" element={<MainLayout><Transactions /></MainLayout>} />
        <Route path="dashboard" element={<MainLayout><Dashboard /></MainLayout>} />
        <Route path="userprofile" element={<MainLayout><UserProfile /></MainLayout>} />
        <Route path="setpassword" element={<Setpassword />} />
        <Route path="forgotpassword" element={<Forgotpassword />} />
        <Route path="forgotpassword/verify" element={<ForgotPasswordVerify />} />
        <Route path="backtest" element={<BackTestMainLayout ><BackTest /></BackTestMainLayout >} />
        <Route path="backtest/history" element={<MainLayout><BackTestHistory /></MainLayout>} />
        <Route path="completeprofile" element={<CompleteProfile />} />
      </Routes>
    </div>
  );
}

export default App;