import { useEffect, useRef, useState } from 'react';
import Images from "../assets/images/index";
import { useFetchUser } from '../services/user.service';
import { useNavigate } from "react-router-dom";
import { storage } from '../utils/storage';
import IResponse from '../types/response';
import { IUser } from '../types/user';
import useRenewAccessToken, { statusWiseOnboardingRedirectToPage, isUserExchangeStatusHasPermission, isTokenValid, isNotNullUndefined } from '../helpers/common';
import { useExchangeSendVerificationCode, useUpdateUserExchangeAccountProcessStatus } from '../services/exchange.service';
import * as Sentry from "@sentry/react";

const Consent = () => {
    const navigate = useNavigate();
    const userExchangeStatusCode = useRef("");
    const userExchangeAccountId = useRef("");
    const [isLoading, setIsLoading] = useState(false)
    const [isAgreed, setIsAgreed] = useState(false);
    const renewAccessToken = useRenewAccessToken();
    const [user, setUser] = useState<IUser>({
        userId: '',
        userRoleId: 0,
        userRoleName: '',
        email: '',
        userName: '',
        phoneNumber: '',
        countryId: 0,
        dialingCode: '',
        isActive: false,
        exchangeAccounts: []
    });
    const [consentError, setConsentError] = useState('');
    const [formResponseError, setFormResponseError] = useState('');
    const checkValidation = () => {
        let isValid = true;

        if (!isAgreed) {
            setConsentError('Agree to the consent to proceed');
            isValid = false;
        } else {
            setConsentError('');
        }
        return isValid;
    };

    const fetchUserMutation = useFetchUser({
        onSuccess: async (response: IResponse) => {
            if (response.statusCode === 200) {
                setUser(response.data);
            }
            if (!isTokenValid(response)) {
                let tokenRenewed = await renewAccessToken();
                if (isNotNullUndefined(tokenRenewed) && tokenRenewed.statusCode === 200) {
                    fetchUser();
                }
            }
            else {
                setFormResponseError(response.statusMessage);
            }
        },
        onError: (error) => {
            window.localStorage.clear();
            navigate("/")
            Sentry.captureException(error);
            setFormResponseError(error.message);
        }
    });

    const fetchUser = async () => {
        fetchUserMutation.mutate({});
    };

    const setCodeType = (user: any): string => {
        let codeType = '';
        if (isNotNullUndefined(user.email) && isNotNullUndefined(user.phoneNumber)) {
            codeType = 'both';
        } else if (isNotNullUndefined(user.email)) {
            codeType = 'email';
        } else if (isNotNullUndefined(user.phoneNumber)) {
            codeType = 'sms';
        }
        storage.setCodeType(codeType);
        return codeType;
    };

    const sendExchangeVerificationCodeMutation = useExchangeSendVerificationCode({
        onSuccess: async (response: IResponse) => {
            if (response.statusCode === 200) {
                userExchangeStatusCode.current = "EXCHANGE_CONSENT_SENT";
                updateExchangeProcessStatusMutation.mutate({ "userExchangeAccountId": userExchangeAccountId.current, "statusCode": userExchangeStatusCode.current });
            }
            if (!isTokenValid(response)) {
                let tokenRenewed = await renewAccessToken();
                if (isNotNullUndefined(tokenRenewed) && tokenRenewed.statusCode === 200) {
                    let codeType = setCodeType(user);
                    sendExchangeVerificationCodeMutation.mutate({ userId: user.userId, codeType: codeType });
                }
            }
            else {
                setIsLoading(false);
                setFormResponseError(response.statusMessage);
            }
        },
        onError: (error) => {
            setIsLoading(false);
            Sentry.captureException(error);
            setFormResponseError(error.message);
        }
    });

    const onAgreed = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkValidation()) {
            setIsLoading(true);
            let codeType = setCodeType(user);
            sendExchangeVerificationCodeMutation.mutate({ userId: user.userId, codeType: codeType });
        }
    };

    const updateExchangeProcessStatusMutation = useUpdateUserExchangeAccountProcessStatus({
        onSuccess: async (response: IResponse) => {
            if (response.statusCode === 200) {
                if (userExchangeStatusCode.current === "EXCHANGE_CONSENT_SENT") {
                    storage.setUserExchangeStatusId(7);
                    navigate('/connect/binance/verify');
                }
            }
            if (!isTokenValid(response)) {
                setIsLoading(false);
                let tokenRenewed = await renewAccessToken();
                if (isNotNullUndefined(tokenRenewed) && tokenRenewed.statusCode === 200) {
                    updateExchangeProcessStatusMutation.mutate({ "userExchangeAccountId": userExchangeAccountId.current, "statusCode": userExchangeStatusCode.current });
                }
            }
            else {
                setIsLoading(false);
                setFormResponseError(response.statusMessage);
            }
        },
        onError: (error) => {
            setIsLoading(false);
            Sentry.captureException(error);
            setFormResponseError(error.message);
        }
    });

    useEffect(() => {
        fetchUser();
        userExchangeAccountId.current = storage.getUserExchangeAccountId() ?? "";
        if (isUserExchangeStatusHasPermission(8)) {
            navigate(statusWiseOnboardingRedirectToPage());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="bg-black-russian">
            <div className="grid grid-cols-1 lg:grid-cols-2 mx-auto max-h-full min-h-screen">
                <div className='hidden lg:block'>
                    <div className="left mx-auto w-full xl:p-0 bg-pattern-1 bg-cover bg-no-repeat bg-center h-full">
                        <div className='flex justify-center'>
                            <img
                                className="mt-[75px] lg:w-56 w-36 sm:w-48 flex"
                                src={Images.ailtralogo}
                                alt="Ailtra Logo" draggable="false" />
                        </div>
                        <div className='flex justify-center items-center'>
                            <div className='w-[562px]'>
                                <div className='flex justify-center my-8 lg:my-[100px]'>
                                    <img
                                        className="bg-contain w-3/5 2xl:w-3/4 bg-no-repeat"
                                        src={Images.consent}
                                        draggable="false"
                                        alt="consent png" />
                                </div>
                                <div className="bg-black-BlackCat text-white font-semibold p-8 mx-5 h-auto self-end">
                                    <p className="text-15 xl:text-lg"> Trusted by thousands of finance platforms </p>
                                    <div className="md:flex md:justify-between md:items-center mt-6">
                                        <img src={Images.binancefulllogo} draggable="false" alt="binancefulllogo" className="xl:w-24 w-24 h-auto md:m-0 mx-auto my-3" />
                                        <img src={Images.coinify} draggable="false" alt="coinify" className="xl:w-32 w-24 h-auto md:m-0 mx-auto my-3" />
                                        <img src={Images.coindest} draggable="false" alt="coindest" className="xl:w-36 w-24 h-auto md:m-0 mx-auto my-3" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right bg-white flex justify-center items-center rounded-t-none p-4 lg:p-0 lg:rounded-tl-3xl lg:rounded-bl-3xl lg:rounded-tr-none h-full">
                    <div className='w-[340px] 2xl:w-[388px]'>
                        <div className="flex justify-center">
                            <img src={Images.consentwelcome} draggable="false" alt="Consent welcome" className="md:w-60 3xl:w-72 w-44" />
                        </div>
                        <div className="mt-12 sm:mt-14">
                            <h4 className="text-lg text-center font-bold "> User consent </h4>
                            <p className="text-center text-sm font-normal sm:leading-6 text-grey-storm mt-6">
                                I, <span className='font-semibold'>{user.userName}</span>, hereby grant permission and authorize Ailtra to
                                initiate trades on my behalf in the selected exchange using the AI
                                trading bot. I understand that Ailtra will use advanced algorithms
                                and strategies to execute trades based on market conditions and my
                                configured settings.
                            </p>
                            <p className="text-center text-sm font-normal sm:leading-6 text-grey-storm mt-6">
                                I acknowledge that trading in the cryptocurrency market involves
                                risks, and I agree to bear the responsibility for any potential
                                gains or losses resulting from these trades.
                            </p>
                        </div>
                        <form onSubmit={onAgreed}>
                            <div className="mt-12 sm:mt-14 relative">
                                <div className="flex items-center">
                                    <input
                                        checked={isAgreed}
                                        onChange={(e) => setIsAgreed(e.target.checked)}
                                        type="checkbox"
                                        id="link-checkbox"
                                        name="A3-confirmation"
                                        className="form-checkbox h-[31px] w-[43px] absolute opacity-0 cursor-pointer"
                                        value="yes"
                                    />
                                    <span
                                        className={`checkmark border border-blue-rock bg-white rounded-md h-[22px] w-[43px] flex items-center justify-center cursor-pointer ${isAgreed ? 'bg-gradient' : ''
                                            }`}
                                    >
                                        {isAgreed && (
                                            <svg
                                                className="fill-current text-white rounded-[5px] p-0.5 cursor-pointer"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                            >
                                                <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                                            </svg>
                                        )}
                                    </span>
                                    <label htmlFor="link-checkbox" className="select-none cursor-pointer 3xl:text-xs text-10 font-normal leading-4 2xl:leading-6 text-grey-storm ml-3">
                                        <p>I agree to Ailtra's ,<a href='https://ailtra.ai/terms-conditions/' target='_blank' rel='noreferrer' className='underline cursor-pointer'>terms and conditions</a>, <a href='https://ailtra.ai/privacy-policy/' target='_blank' rel='noreferrer' className='underline cursor-pointer'>privacy policy </a>, &
                                            hereby confirm my consent to the above legal statement.</p>
                                    </label>
                                </div>
                                {consentError && <p className="text-red-500 text-xs mt-1 text-center font-semibold -bottom-4 absolute w-full">{consentError}</p>}
                            </div>
                            <div className="mt-6 xl:mt-6 relative">
                                <button
                                    type="submit"
                                    disabled={isLoading}
                                    className={`w-full flex justify-center items-center py-3.5 font-bold text-sm text-white btn-hover-animation focus-visible:bg-[right_center] hover:bg-[right_center] bg-gradient-hover cursor-pointer outline-none ${isLoading ? 'bg-gradient-hover opacity-60' : 'bg-gradient-hover'}`} >
                                    {isLoading ? (
                                        <p className='flex items-center'>
                                            <span className='w-4 h-4 border-2 border-dashed animate-spin rounded-full mr-2'>
                                            </span>
                                            Proceeding...
                                        </p>
                                    ) : (
                                        'AGREED & PROCEED'
                                    )}
                                </button>
                                {formResponseError && <p className="text-red-500 text-xs font-semibold mt-1 text-center -bottom-4.5 absolute w-full">{formResponseError}</p>}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Consent;