import Images from "../assets/images";
import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import { useGetSystemAdminToken, useGetUsers, useGetUserClone } from "../services/user.service";
import IResponse from "../types/response";
import Pagination from "../helpers/pagination";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import Modal from 'react-modal';
import GaugeChart from 'react-gauge-chart';
import useRenewAccessToken, { filterUniqueData, formatDateTime, handleError, isNotNullUndefined, isNotNullUndefinedBlank, isTokenValid, isUserStatusHasPermission, statusWiseOnboardingRedirectToPage } from "../helpers/common";
import { formatNumberWithCommas } from "../helpers/commaformattednumber";
import { storage } from "../utils/storage";
import { useNavigate } from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";

const User = () => {
    const navigate = useNavigate();
    const [users, setUsersData] = useState<any>([]);
    const isUsersDataLoading = useRef(false);
    const [searchedUsers, setSearchedUsers] = useState<any>([]);
    const [user, setUser] = useState<any>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [recordCount, setRecordCount] = useState<number>(0);

    const [dateRangeValues, setDateRangeValues] = useState({
        startDate: null,
        endDate: null
    });
    const [startDateMillisecond, setStartDateMillisecond] = useState<number>(0);
    const [endDateMillisecond, setEndDateMillisecond] = useState<number>(0);

    const [accountStatusType, setAccountStatusType] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [gaugeValue] = useState<number>(0.3);
    const renewAccessToken = useRenewAccessToken();

    const [isAccountStatusOpen, setAccountStatusOpen] = useState(false);
    const [isReferralSourceOpen, setIsReferralSourceOpen] = useState(false);

    const [isSortByOpen, setIsSortByOpen] = useState(false);
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const totalPages = Math.ceil(recordCount / pageSize);
    const referralSourceSearchText = useRef("");
    const sortBy = useRef("");
    const referralByUserId = useRef("");
    const referralByUserName = useRef("");
    const searchText = useRef("");
    const [, setRenderTrigger] = useState(0);
    const [isUserPageSizeOpen, setIsUserPageSizeOpen] = useState(false);

    const chartStyle: React.CSSProperties = {
        width: 120,
    };

    interface IUserExchangeTestAccount {
        email: string;
        startDate: Date | null;
        endDate: Date | null;
        walletBalanceAmount: number;
        accountStatusId: number;
        accountStatus: string;
    };

    const [userExchangeTestAccount, setUserExchangeTestAccount] = useState<IUserExchangeTestAccount>({
        email: "",
        startDate: new Date(),
        endDate: new Date(),
        walletBalanceAmount: 0,
        accountStatusId: 0,
        accountStatus: ""
    });

    const accountStatusTypes = [{ id: 1, name: "ACTIVE" },
    { id: 2, name: "PROFILE_UPDATED" },
    { id: 3, name: "OTP_VERIFIED" },
    { id: 4, name: "OTP_SENT" },
    { id: 4, name: "REGISTERED" }
    ];

    const sortByOption = [
        { id: 1, name: "Floating" },
        { id: 2, name: "Account Risk" },
        { id: 3, name: "Total Profit" },
        { id: 4, name: "Highest StopLoss count" },
        { id: 5, name: "Account Age" },
        { id: 6, name: "Daily Profit" },
        { id: 7, name: "Available Fuel" },
        { id: 8, name: "Current Future Balance" },
        { id: 9, name: "Top Referral" }
    ];

    const pageSizeOptions = ["10", "20", "50", "100"];

    useEffect(() => {
        if (isUserStatusHasPermission(0)) {
            navigate(statusWiseOnboardingRedirectToPage());
        }
        setUsersData([]);
        isUsersDataLoading.current = true;
        getUserClone.mutate({ pageNumber: pageNumber, pageSize: pageSize, registerstartDate: startDateMillisecond, registerendDate: endDateMillisecond, accountStatusType: accountStatusType, userRoleId: 2, referralByUserId: '', searchKeyword: searchText.current, sortBy: sortBy.current });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, pageSize]);

    const getUserClone = useGetUserClone({
        onSuccess: async (response: IResponse) => {
            if (!isTokenValid(response)) {
                let tokenRenewed = await renewAccessToken();
                if (isNotNullUndefined(tokenRenewed) && tokenRenewed.statusCode === 200) {
                    isUsersDataLoading.current = true;
                    getUserClone.mutate({ pageNumber: pageNumber, pageSize: pageSize, registerstartDate: startDateMillisecond, registerendDate: endDateMillisecond, accountStatusType: accountStatusType, userRoleId: 2, referralByUserId: referralByUserId.current, searchKeyword: searchText.current, sortBy: sortBy.current });
                }
            }
            else if (isNotNullUndefined(response.data) && response.data.length > 0) {
                setUsersData(filterUniqueData([...response.data], 'userId'));
                setRecordCount(response.recordCount);
                isUsersDataLoading.current = false;
            } else {
                setUsersData([]);
                isUsersDataLoading.current = false;
                setRecordCount(0);
            }
        },
        onError: (error) => {
            handleError(error);
        },
    });

    const getUsers = useGetUsers({
        onSuccess: async (response: IResponse) => {
            if (!isTokenValid(response)) {
                let tokenRenewed = await renewAccessToken();
                if (isNotNullUndefined(tokenRenewed) && tokenRenewed.statusCode === 200) {
                    getUsers.mutate({ pageNumber: 1, pageSize: 100, registerstartDate: 0, registerendDate: 0, accountStatusType: '', userRoleId: 2, searchKeyword: referralSourceSearchText.current });
                }
            }
            else if (isNotNullUndefined(response.data) && response.data.length > 0) {
                setSearchedUsers(response.data);
            }
        },
        onError: (error) => {
            handleError(error);
        },
    });

    const handlePageChange = (newPage: number) => {
        setPageNumber(newPage);
        let size = pageSize;
        setPageSize(size);
    };

    const toggleUserPageSizeDropdown = () => {
        setIsUserPageSizeOpen(!isUserPageSizeOpen);
    }

    const handleUserPageSizeChange = (option: any) => {
        setPageSize(parseInt(option));
        let pageCount = Math.ceil(recordCount / parseInt(option));
        let currentPage = pageCount < pageNumber ? pageCount : pageNumber;
        setPageNumber(currentPage);
        setIsUserPageSizeOpen(false);
    };

    const handleDateRangeValueChange = (newValue: any) => {
        let startDate = new Date(newValue.startDate);
        let endDate = new Date(newValue.endDate);
        const currentDate = new Date();
        if (startDate.toDateString() === endDate.toDateString()) {
            startDate.setUTCHours(0, 0, 0, 1);
        }
        if (endDate.toDateString() === currentDate.toDateString()) {
            endDate = new Date();
        } else {
            endDate.setUTCHours(23, 59, 59, 999);
        }
        setDateRangeValues(newValue);
        setStartDateMillisecond(startDate.getTime());
        setEndDateMillisecond(endDate.getTime());
    };

    function onClickAccountStatusDropdown() {
        setAccountStatusOpen(!isAccountStatusOpen);
    };

    const onAccountStatusChange = (accountStatus: any) => {
        if (accountStatus === null) {
            const userExchangeTestAccountUpdated = {
                ...userExchangeTestAccount,
                accountStatusId: 0,
                accountStatus: ''
            };
            setUserExchangeTestAccount(userExchangeTestAccountUpdated);
            setAccountStatusType('')
        }
        else {
            const userExchangeTestAccountUpdated = {
                ...userExchangeTestAccount,
                accountStatusId: accountStatus.id,
                accountStatus: accountStatus.name
            };
            setUserExchangeTestAccount(userExchangeTestAccountUpdated);
            setAccountStatusType(accountStatus.name)
        }
        setAccountStatusOpen(false);
    };

    function onClickReferralSourceDropdown() {
        setIsReferralSourceOpen(!isReferralSourceOpen);
    };

    const onSearchUserReferralSource = (event: ChangeEvent<HTMLInputElement>) => {
        referralSourceSearchText.current = event.target.value;
        if (referralSourceSearchText.current.length >= 3) {
            getUsers.mutate({ pageNumber: 1, pageSize: 100, registerstartDate: 0, registerendDate: 0, accountStatusType: '', userRoleId: 2, searchKeyword: referralSourceSearchText.current });
        }
        else {
            setSearchedUsers([]);
        }
    };

    const onSearchReferralSource = (searchedUser: any) => {
        referralByUserId.current = searchedUser.userId;
        referralByUserName.current = searchedUser.username;
        onClickReferralSourceDropdown();
    };

    const onSearchUser = (event: ChangeEvent<HTMLInputElement>) => {
        searchText.current = event.target.value;
        //isUsersDataLoading.current = true;
        setRenderTrigger(prev => prev + 1);
        if (searchText.current.length >= 3 || searchText.current.length === 0) {
            setTimeout(() => {
                getUserClone.mutate({ pageNumber: pageNumber, pageSize: pageSize, registerstartDate: startDateMillisecond, registerendDate: endDateMillisecond, accountStatusType: accountStatusType, userRoleId: 2, referralByUserId: referralByUserId.current, searchKeyword: searchText.current });
            }, 150);
        }
    };

    function onClickSortByDropdown() {
        setIsSortByOpen(!isSortByOpen);
    };

    const onSortByChange = (value: any) => {
        sortBy.current = value;
        setIsSortByOpen(false);
        if (!isNotNullUndefinedBlank(value) && isNotNullUndefined((document.getElementById("ddlSortByOption") as any))) {
            (document.getElementById("ddlSortByOption") as any).childNodes[0].nodeValue = value;
        }
        onSortByType();
    };

    const onSortByType = () => {
        if (users.length > 0) {
            sortBy.current = isNotNullUndefinedBlank(sortBy.current) ? sortBy.current : "";
            getUserClone.mutate({ pageNumber: pageNumber, pageSize: pageSize, registerstartDate: startDateMillisecond, registerendDate: endDateMillisecond, accountStatusType: accountStatusType, userRoleId: 2, referralByUserId: referralByUserId.current, searchKeyword: searchText.current, sortBy: sortBy.current });
        }
    };

    const openUserDetailModal = (user: any) => {
        setUser(user);
        setIsModalOpen(true);
    };

    const closeUserDetailModal = () => {
        setIsModalOpen(false);
    };

    const applyFilters = () => {
        setLoading(true);
        if (startDateMillisecond > 0 || endDateMillisecond > 0 || isNotNullUndefinedBlank(accountStatusType) || isNotNullUndefinedBlank(referralByUserId.current)) {
            try {
                isUsersDataLoading.current = true;
                getUserClone.mutate({
                    pageNumber,
                    pageSize,
                    registerstartDate: startDateMillisecond,
                    registerendDate: endDateMillisecond,
                    accountStatusType: accountStatusType,
                    userRoleId: 2,
                    referralByUserId: referralByUserId.current,
                    searchKeyword: searchText.current,
                    sortBy: sortBy.current
                });

            } catch (error) {
                console.error('Error fetching data:', error);
            }

        } else {
            alert('Start date, end date or account status are required.');
        }
        setLoading(false);
    };

    const clearfilters = () => {
        setUserExchangeTestAccount({
            ...userExchangeTestAccount,
            accountStatusId: 0,
            accountStatus: "",
            startDate: null,
            endDate: null
        });
        referralByUserId.current = '';
        referralByUserName.current = '';
        referralSourceSearchText.current = '';
        searchText.current = '';
        sortBy.current = '';

        setSearchedUsers([]);
        setDateRangeValues({ startDate: null, endDate: null });
        setAccountStatusType('');
        setStartDateMillisecond(0);
        setEndDateMillisecond(0);
        setIsReferralSourceOpen(false);
        setAccountStatusOpen(false);
        isUsersDataLoading.current = true;
        getUserClone.mutate({
            pageNumber: pageNumber,
            pageSize: pageSize,
            registerstartDate: 0,
            registerendDate: 0,
            accountStatusType: '',
            userRoleId: 2,
            referralByUserId: '',
            searchKeyword: searchText.current,
            sortBy: null
        });
    };

    const onAccessAccount = () => {
        getSystemAdminToken.mutate({ userId: user.userId });
    };

    const getSystemAdminToken = useGetSystemAdminToken({
        onSuccess: async (response: IResponse) => {
            if (!isTokenValid(response)) {
                let tokenRenewed = await renewAccessToken();
                if (isNotNullUndefined(tokenRenewed) && tokenRenewed.statusCode === 200) {
                    getSystemAdminToken.mutate({ userId: user.userId });
                }
            }
            else if (isNotNullUndefined(response.data)) {
                storage.setToken(response.data.refreshToken);
                storage.setIsAdminLoginAsUser("true");
                navigate("/dashboard");
            }
        },
        onError: (error) => {
            handleError(error);
        },
    });

    return (
        <div className="py-8 px-5 sm:px-9">
            <div className="flex items-center justify-between w-full">
                <span className="text-xs sm:text-lg text-white font-normal">
                    User Filters
                </span>
                <div className="border border-t-0 border-white border-opacity-12 w-[calc(100%-75px)] sm:w-[calc(100%-108px)]"></div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-4 gap-4 mt-4 items-end">
                <div>
                    <div className="bg-grey-bunker text-white font-normal text-xs md:text-sm cursor-pointer focus:outline-none mt-2 w-full flex justify-between items-center">
                        <Datepicker
                            inputId="startDatePicker"
                            inputClassName="bg-grey-bunker w-full text-grey-storm font-normal pl-2.5 pr-2.5 py-2.5 md:py-4 focus:outline-0"
                            primaryColor={"green"}
                            containerClassName="relative w-full datepicker-select-popup"
                            value={dateRangeValues}
                            placeholder={"Filter by account creation date"}
                            onChange={handleDateRangeValueChange}
                            showShortcuts={true}
                            useRange={false}
                            maxDate={new Date()}
                            readOnly={true}
                        />
                    </div>
                </div>
                <div>
                    <div className="relative">
                        <p className="text-10 md:text-sm font-normal text-grey-nobel">Account Status</p>
                        <div
                            className="bg-grey-bunker text-white font-normal text-xs md:text-sm cursor-pointer pl-2.5 pr-2.5 py-2.5 md:py-4 focus:outline-none mt-2 w-full flex justify-between items-center"
                            onClick={onClickAccountStatusDropdown}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    onClickAccountStatusDropdown();
                                }
                            }}
                            role="menu" tabIndex={0}
                        >
                            {userExchangeTestAccount.accountStatus ? (
                                <span className={`text-grey-storm text-sm ${userExchangeTestAccount.accountStatus ? 'text-white text-xs md:text-sm' : ''}`}>{userExchangeTestAccount.accountStatus}</span>
                            ) : (
                                <span className="text-grey-storm text-11 md:text-sm mr-2.5">Filter users by their account status</span>
                            )}
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path
                                    d="M11.9465 5.45331H7.79316H4.05317C3.41317 5.45331 3.09317 6.22664 3.5465 6.67997L6.99983 10.1333C7.55317 10.6866 8.45317 10.6866 9.0065 10.1333L10.3198 8.81997L12.4598 6.67997C12.9065 6.22664 12.5865 5.45331 11.9465 5.45331Z"
                                    fill="white" />
                            </svg>
                        </div>
                        {isAccountStatusOpen && (
                            <div className="absolute mt-1 w-full text-white bg-grey-bunker shadow-lg cursor-pointer z-10"
                                onMouseLeave={() => setAccountStatusOpen(false)}
                                role="menu" tabIndex={0}>
                                {accountStatusTypes.map((accountStatus: any) => (
                                    <button
                                        key={accountStatus.id}
                                        className="py-1 px-3 hover:bg-white hover:text-black-pearl text-xs md:text-sm cursor-pointer w-full text-left"
                                        onClick={() => onAccountStatusChange(accountStatus)}
                                    >
                                        {accountStatus.name}
                                    </button>
                                ))}
                            </div>
                        )}
                        {userExchangeTestAccount.accountStatus && (
                            <button
                                className="absolute right-2.5 top-[68%] p-1 bg-grey-bunker rounded-full transform -translate-y-1/2 cursor-pointer"
                                onClick={() => {
                                    setUserExchangeTestAccount({
                                        ...userExchangeTestAccount,
                                        accountStatus: ""
                                    });
                                    setAccountStatusOpen(false);
                                    setAccountStatusType('');
                                }}>
                                <svg width="8" height="8" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.26331 4.49961L8.67581 1.09253C8.77781 0.990531 8.83511 0.852192 8.83511 0.707946C8.83511 0.563699 8.77781 0.42536 8.67581 0.323362C8.57381 0.221364 8.43547 0.164062 8.29123 0.164062C8.14698 0.164062 8.00864 0.221364 7.90664 0.323362L4.49956 3.73586L1.09248 0.323362C0.990479 0.221364 0.85214 0.164063 0.707893 0.164063C0.563647 0.164063 0.425308 0.221364 0.32331 0.323362C0.221312 0.42536 0.16401 0.563699 0.16401 0.707946C0.16401 0.852192 0.221312 0.990531 0.32331 1.09253L3.73581 4.49961L0.32331 7.9067C0.27254 7.95705 0.232244 8.01696 0.204744 8.08297C0.177244 8.14897 0.163086 8.21977 0.163086 8.29128C0.163086 8.36279 0.177244 8.43358 0.204744 8.49959C0.232244 8.5656 0.27254 8.62551 0.32331 8.67586C0.373665 8.72663 0.433574 8.76693 0.499581 8.79443C0.565588 8.82193 0.636387 8.83609 0.707893 8.83609C0.7794 8.83609 0.850199 8.82193 0.916206 8.79443C0.982213 8.76693 1.04212 8.72663 1.09248 8.67586L4.49956 5.26336L7.90664 8.67586C7.957 8.72663 8.01691 8.76693 8.08291 8.79443C8.14892 8.82193 8.21972 8.83609 8.29123 8.83609C8.36273 8.83609 8.43353 8.82193 8.49954 8.79443C8.56555 8.76693 8.62545 8.72663 8.67581 8.67586C8.72658 8.62551 8.76688 8.5656 8.79438 8.49959C8.82188 8.43358 8.83603 8.36279 8.83603 8.29128C8.83603 8.21977 8.82188 8.14897 8.79438 8.08297C8.76688 8.01696 8.72658 7.95705 8.67581 7.9067L5.26331 4.49961Z" fill="white" />
                                </svg>
                            </button>
                        )}
                    </div>
                </div>
                <div>
                    <div className="relative">
                        <p className="text-11 md:text-sm font-normal text-grey-nobel">Referral Source</p>
                        <div
                            className="bg-grey-bunker text-white font-normal text-xs md:text-sm cursor-pointer pl-2.5 pr-2.5 py-2.5 md:py-4 focus:outline-none mt-2 w-full flex justify-between items-center"
                            onClick={onClickReferralSourceDropdown}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    onClickReferralSourceDropdown();
                                }
                            }}
                            role="menu" tabIndex={0}
                        >
                            {referralByUserName.current ? (
                                <span className={`text-grey-storm text-sm ${referralByUserName.current ? 'text-white text-xs md:text-sm' : ''}`}>
                                    {referralByUserName.current}
                                </span>
                            ) : (
                                <span className="text-grey-storm text-11 md:text-sm mr-2.5">Filter users by their account status</span>
                            )}
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path
                                    d="M11.9465 5.45331H7.79316H4.05317C3.41317 5.45331 3.09317 6.22664 3.5465 6.67997L6.99983 10.1333C7.55317 10.6866 8.45317 10.6866 9.0065 10.1333L10.3198 8.81997L12.4598 6.67997C12.9065 6.22664 12.5865 5.45331 11.9465 5.45331Z"
                                    fill="white" />
                            </svg>
                        </div>
                        {isReferralSourceOpen && (
                            <div className="absolute mt-1 w-full lg:w-[calc(100%--171px)] text-white bg-grey-bunker shadow-lg cursor-pointer z-10"
                                onMouseLeave={() => setIsReferralSourceOpen(false)}
                                role="menu" tabIndex={0}>
                                <div className="md:flex relative pt-2 px-2">
                                    <div className="inline-flex items-center justify-center absolute left-0 top-1 h-full w-6 ml-6">
                                        <img src={Images.magnifer} alt="Magnifer" />
                                    </div>
                                    <input type="text"
                                        id="referralSearch"
                                        name="referralSearch"
                                        value={referralSourceSearchText.current}
                                        onChange={onSearchUserReferralSource}
                                        className="text-15 text-white font-normal pl-14 pr-4 bg-grey-ebony-Clay w-full h-[42px] focus:outline-none"
                                        placeholder="Search here"
                                    />
                                </div>
                                <div className="w-full overflow-auto min-h-[50px] max-h-[375px]">
                                    {searchedUsers.length > 0 ?
                                        <table className="w-full">
                                            <thead>
                                                <tr className="border-b-2 border-grey-ebony-Clay mb-1">
                                                    <th className="px-4 py-3 pb-2 text-start text-xs md:text-sm whitespace-nowrap font-semibold text-white tracking-0.16 min-w-[175px]">
                                                        User name</th>
                                                    <th className="px-4 py-3 text-start text-xs md:text-sm whitespace-nowrap font-semibold text-white tracking-0.16 min-w-[250px]">
                                                        Email address</th>
                                                    <th className="px-4 py-3 text-start text-xs md:text-sm whitespace-nowrap font-semibold text-white tracking-0.16 min-w-[130px]">
                                                        Referral code</th>
                                                </tr>
                                            </thead>
                                            <tbody className="mt-1">
                                                {searchedUsers.map((user: any) => (
                                                    <tr key={user.userId} onClick={() => onSearchReferralSource(user)} className="border-b border-grey-ebony-Clay mb-1 hover:bg-black-blackRock">
                                                        <td className="py-3 px-4 text-xs">{user.username}</td>
                                                        <td className="py-3 px-4 text-xs">{user.email}</td>
                                                        <td className="py-3 px-4 text-xs">{user.referralCode}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table> : ""
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex gap-4">
                    <button
                        disabled={loading}
                        onClick={applyFilters}
                        className={`relative w-1/2 flex items-center justify-center cursor-pointer h-10 md:h-[52px] font-bold text-xs md:text-sm transition-all text-white btn-hover-animation focus-visible:bg-[right_center] hover:bg-[right_center] outline-none ${loading ? 'bg-gradient-hover opacity-60' : 'bg-gradient-hover'}`} >
                        {loading ? (
                            <p className='flex items-center'><span className='w-4 h-4 border-2 border-dashed animate-spin rounded-full mr-2'>{''}</span>Applying...</p>
                        ) : (
                            'APPLY FILTER'
                        )}
                    </button>
                    <button
                        className="w-1/2 h-10 md:h-[52px] font-bold text-xs md:text-sm flex justify-evenly items-center mx-auto bg-gradient text-gradient bg-clip-text  border-2 border-bottom tracking-[0.28px] relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:bg-active-gradient after:transition after:duration-[0.4s] after:ease-out hover:after:opacity-100" onClick={clearfilters}>
                        <div className="w-6 h-6">
                            <img src={Images.clearfilter} alt="Rotateleft" className="w-full" />
                        </div>
                        Clear Filter
                    </button>
                </div>
            </div>
            <div className="block sm:flex justify-end items-center mt-10">
                <div className="flex relative">
                    <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-5 ml-2">
                        <img src={Images.magnifer} alt="Magnifer" />
                    </div>
                    <input type="text"
                        id="search"
                        name="search"
                        value={searchText.current}
                        onChange={onSearchUser}
                        // autoComplete="off"
                        className="text-xs text-white font-normal pl-10 pr-4 bg-grey-bunker w-full sm:w-80 h-10 focus:outline-none caret-white placeholder-grey-storm placeholder:text-11 md:placeholder:text-sm"
                        placeholder="Search users by name, email, or ID..."
                    />
                </div>
                <div className="mt-2.5 sm:mt-0 justify-end sm:ml-3 flex items-center">
                    <p className="text-10 md:text-sm font-normal text-grey-nobel mr-3 ml-6">
                        Sort by
                    </p>

                    <div className="relative">
                        <div
                            className="bg-grey-bunker text-white font-normal text-xs md:text-sm cursor-pointer pl-2.5 pr-2.5 py-2.5 focus:outline-none w-[225px] flex justify-between items-center"
                            onClick={onClickSortByDropdown}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    onClickSortByDropdown();
                                }
                            }}
                            role="menu" tabIndex={0}
                        >
                            {isNotNullUndefinedBlank(sortBy.current) ? (
                                <span className={`text-grey-nobel mr-2.5 ${sortBy.current ? 'text-white text-xs md:text-sm' : ''}`}
                                    id="ddlSortByOption">
                                    {sortBy.current}
                                </span>
                            ) : (
                                <span className="text-grey-storm text-11 md:text-sm mr-2.5" id="ddlSortByDefaultOption">Select from</span>
                            )}
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path
                                    d="M11.9465 5.45331H7.79316H4.05317C3.41317 5.45331 3.09317 6.22664 3.5465 6.67997L6.99983 10.1333C7.55317 10.6866 8.45317 10.6866 9.0065 10.1333L10.3198 8.81997L12.4598 6.67997C12.9065 6.22664 12.5865 5.45331 11.9465 5.45331Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                        {isSortByOpen && (
                            <div className="absolute mt-1 text-white bg-grey-bunker shadow-lg w-56 right-0 cursor-pointer"
                                onMouseLeave={() => setIsSortByOpen(false)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        setIsSortByOpen(false);
                                    }
                                }}
                                role="menu" tabIndex={0}
                            >
                                {sortByOption.map((sortByOption: any) => (
                                    <button
                                        key={sortByOption.id}
                                        className={`py-1 px-3 text-left hover:bg-white hover:text-black-pearl w-full text-xs md:text-sm cursor-pointer relative ${sortBy.current === sortByOption.name ? 'relative inline-block after:absolute after:w-2 after:h-2 after:bg-gradient after:rounded-full after:top-1/2 after:right-2.5 after:-translate-y-1/2' : ''}`}
                                        onClick={() => onSortByChange(sortByOption.name)}>
                                        {sortByOption.name}
                                    </button>
                                ))}
                            </div>
                        )}
                        {isNotNullUndefinedBlank(sortBy.current) && (
                            <div id="ddlClearSortBy"
                                className="absolute right-2.5 top-1/2 p-1 bg-grey-bunker rounded-full transform -translate-y-1/2 cursor-pointer"
                                onClick={() => onSortByChange("")}
                            >
                                <svg width="8" height="8" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.26331 4.49961L8.67581 1.09253C8.77781 0.990531 8.83511 0.852192 8.83511 0.707946C8.83511 0.563699 8.77781 0.42536 8.67581 0.323362C8.57381 0.221364 8.43547 0.164062 8.29123 0.164062C8.14698 0.164062 8.00864 0.221364 7.90664 0.323362L4.49956 3.73586L1.09248 0.323362C0.990479 0.221364 0.85214 0.164063 0.707893 0.164063C0.563647 0.164063 0.425308 0.221364 0.32331 0.323362C0.221312 0.42536 0.16401 0.563699 0.16401 0.707946C0.16401 0.852192 0.221312 0.990531 0.32331 1.09253L3.73581 4.49961L0.32331 7.9067C0.27254 7.95705 0.232244 8.01696 0.204744 8.08297C0.177244 8.14897 0.163086 8.21977 0.163086 8.29128C0.163086 8.36279 0.177244 8.43358 0.204744 8.49959C0.232244 8.5656 0.27254 8.62551 0.32331 8.67586C0.373665 8.72663 0.433574 8.76693 0.499581 8.79443C0.565588 8.82193 0.636387 8.83609 0.707893 8.83609C0.7794 8.83609 0.850199 8.82193 0.916206 8.79443C0.982213 8.76693 1.04212 8.72663 1.09248 8.67586L4.49956 5.26336L7.90664 8.67586C7.957 8.72663 8.01691 8.76693 8.08291 8.79443C8.14892 8.82193 8.21972 8.83609 8.29123 8.83609C8.36273 8.83609 8.43353 8.82193 8.49954 8.79443C8.56555 8.76693 8.62545 8.72663 8.67581 8.67586C8.72658 8.62551 8.76688 8.5656 8.79438 8.49959C8.82188 8.43358 8.83603 8.36279 8.83603 8.29128C8.83603 8.21977 8.82188 8.14897 8.79438 8.08297C8.76688 8.01696 8.72658 7.95705 8.67581 7.9067L5.26331 4.49961Z" fill="white" />
                                </svg>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="overflow-auto max-h-[calc(100vh-107px)] mt-5">
                {
                    (users.length <= 0 && !isUsersDataLoading.current) ? (
                        <div>
                            <p className="mt-3 w-full text-xl font-normal text-white text-center">No records found</p>
                        </div>
                    ) :
                        (
                            <table className="border-2 border-grey-ebony w-full">
                                <thead>
                                    <tr className="bg-grey-bunker">
                                        <th className="p-2 sm:p-3 xl:p-5 pl-7 text-start text-xs sm:text-sm xl:text-base whitespace-nowrap font-semibold text-white tracking-0.16 min-w-[80px] sm:min-w-[100px]">ID</th>
                                        <th className="p-2 sm:p-3 xl:p-5 text-start text-xs sm:text-sm xl:text-base whitespace-nowrap font-semibold text-white tracking-0.16 min-w-[170px] sm:min-w-[250px]">Username</th>
                                        <th className="p-2 sm:p-3 xl:p-5 text-start text-xs sm:text-sm xl:text-base whitespace-nowrap font-semibold text-white tracking-0.16 min-w-[250px] sm:min-w-[350px]">Email Address</th>
                                        <th className="p-2 sm:p-3 xl:p-5 text-start text-xs sm:text-sm xl:text-base whitespace-nowrap font-semibold text-white tracking-0.16 min-w-[130px] sm:min-w-37.5">Mobile Number</th>
                                        <th className="p-2 sm:p-3 xl:p-5 text-start text-xs sm:text-sm xl:text-base whitespace-nowrap font-semibold text-white tracking-0.16 min-w-[130px] sm:min-w-37.5">Referral Code</th>
                                        <th className="p-2 sm:p-3 xl:p-5 text-start text-xs sm:text-sm xl:text-base whitespace-nowrap font-semibold text-white tracking-0.16 min-w-[170px] sm:min-w-[210px]">Referred by</th>
                                        <th className="p-2 sm:p-3 xl:p-5 text-start text-xs sm:text-sm xl:text-base whitespace-nowrap font-semibold text-white tracking-0.16 min-w-[130px] sm:min-w-42.5">Auth Mode</th>
                                        <th className="p-2 sm:p-3 xl:p-5 text-start text-xs sm:text-sm xl:text-base whitespace-nowrap font-semibold text-white tracking-0.16 min-w-[130px] sm:min-w-37.5">Status</th>
                                        <th className="p-2 sm:p-3 xl:p-5 text-start text-xs sm:text-sm xl:text-base whitespace-nowrap font-semibold text-white tracking-0.16 min-w-[220px] sm:min-w-[230px]">Last Logged In</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (users.length <= 0 && isUsersDataLoading.current) ?
                                            (
                                                Array.from({ length: pageSize }, (_, index) => (
                                                    <tr key={index} className="border-b-2 border-grey-ebony">
                                                        <td className="p-2 sm:p-3 xl:p-5 pl-7 text-start whitespace-nowrap  font-normal text-white tracking-0.16">
                                                            <div className="h-2 bg-grey-tuna rounded-full w-10"></div>
                                                        </td>
                                                        <td className="p-2 sm:p-3 xl:p-5 text-start whitespace-nowrap  font-normal text-white tracking-0.16 flex items-center">
                                                            <div className="w-10 h-10">
                                                                <svg className="w-10 h-10" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                                    <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                                                                </svg>
                                                            </div>
                                                            <span className="ml-2">
                                                                <div className="h-2 bg-grey-tuna rounded-full w-10"></div>
                                                            </span>
                                                        </td>
                                                        <td className="p-2 sm:p-3 xl:p-5 text-start whitespace-nowrap font-normal text-white tracking-0.16">
                                                            <div className="h-2 bg-grey-tuna rounded-full w-10"></div>
                                                        </td>
                                                        <td className="p-2 sm:p-3 xl:p-5 text-start whitespace-nowrap font-normal text-white tracking-0.16">
                                                            <div className="h-2 bg-grey-tuna rounded-full w-10"></div>
                                                        </td>
                                                        <td className="p-2 sm:p-3 xl:p-5 text-start whitespace-nowrap font-normal text-white tracking-0.16">
                                                            <div className="h-2 bg-grey-tuna rounded-full w-10"></div>
                                                        </td>
                                                        <td className="p-2 sm:p-3 xl:p-5 text-start whitespace-nowrap font-normal text-white tracking-0.16">
                                                            <div className="h-2 bg-grey-tuna rounded-full w-10"></div>
                                                        </td>
                                                        <td className="p-2 sm:p-3 xl:p-5 text-start whitespace-nowrap font-normal text-white tracking-0.16">
                                                            <div className="h-2 bg-grey-tuna rounded-full w-10"></div>
                                                        </td>
                                                        <td className="p-2 sm:p-3 xl:p-5 text-start whitespace-nowrap font-normal text-white tracking-0.16">
                                                            <div className="h-2 bg-grey-tuna rounded-full w-10"></div>
                                                        </td>
                                                        <td className="p-2 sm:p-3 xl:p-5 text-start font-normal whitespace-nowrap  text-white tracking-0.16">
                                                            <div className="h-2 bg-grey-tuna rounded-full w-10"></div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                users.map((user: any, ix: number) => (
                                                    <tr key={user.userId} className="border-b-2 border-grey-ebony" onClick={() => openUserDetailModal(user)}>
                                                        <td className="py-2.5 xl:py-4 px-3 xl:px-5 pl-7 text-start whitespace-nowrap  text-11 sm:text-xs lg:text-sm xl:text-base font-normal text-white tracking-0.16"> {ix + 1} </td>
                                                        <td className="py-2.5 xl:py-4 px-3 xl:px-5 text-start whitespace-nowrap  text-11 sm:text-xs lg:text-sm xl:text-base font-normal text-white tracking-0.16 flex items-center">
                                                            <div className="w-6 h-6 md:w-10 md:h-10">
                                                                {isNotNullUndefinedBlank(user.profileImageUrl) ? (
                                                                    <img className="w-full h-full rounded-full" src={user.profileImageUrl} alt="User profile" />
                                                                ) : (
                                                                    <img className="w-full h-full rounded-full" src={Images.userprofile} alt="User profile" />
                                                                )}
                                                            </div>
                                                            <span className="ml-2">{user.username}</span>
                                                        </td>
                                                        <td className="py-2.5 xl:py-4 px-3 xl:px-5 text-start whitespace-nowrap text-11 sm:text-xs lg:text-sm xl:text-base font-normal text-white tracking-0.16"> {user.email} </td>
                                                        <td className="py-2.5 xl:py-4 px-3 xl:px-5 text-start whitespace-nowrap text-11 sm:text-xs lg:text-sm xl:text-base font-normal text-white tracking-0.16"> {user.phoneNumber} </td>
                                                        <td className="py-2.5 xl:py-4 px-3 xl:px-5 text-start whitespace-nowrap text-11 sm:text-xs lg:text-sm xl:text-base font-normal text-white tracking-0.16"> {user.referralCode} </td>
                                                        <td className="py-2.5 xl:py-4 px-3 xl:px-5 text-start whitespace-nowrap text-11 sm:text-xs lg:text-sm xl:text-base font-normal text-white tracking-0.16"> {user.referralBy} </td>
                                                        <td className="py-2.5 xl:py-4 px-3 xl:px-5 text-start whitespace-nowrap text-11 sm:text-xs lg:text-sm xl:text-base font-normal text-white tracking-0.16">
                                                            {user.loginProvider === 'FaceBook' && (
                                                                <FontAwesomeIcon icon={faFacebook} style={{ color: '#3b5998', fontSize: '35px', margin: 'auto' }} />
                                                            )}
                                                            {user.loginProvider === '' && (
                                                                <FontAwesomeIcon icon={faGoogle} style={{ color: '#dd4b39' }} />
                                                            )}
                                                            {user.loginProvider !== 'FaceBook' && user.loginProvider !== 'Google' && user.loginProvider}
                                                        </td>
                                                        <td className="py-2.5 xl:py-4 px-3 xl:px-5 text-start whitespace-nowrap text-11 sm:text-xs lg:text-sm xl:text-base font-normal text-white tracking-0.16">
                                                            {user.statusId === 1 ? (
                                                                <div className="bg-[#22392b] max-w-max">
                                                                    <p className="px-4.5 py-1.5 text-[#3FD068] text-center text-11 sm:text-xs lg:text-sm xl:text-base font-normal">Active</p>
                                                                </div>
                                                            ) : (
                                                                <div className="bg-deactive-gradient max-w-max">
                                                                    <p className="px-4.5 py-1.5 text-center deactive-btn text-11 sm:text-xs lg:text-sm xl:text-base font-normal">Deactive</p>
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td className="py-2.5 xl:py-4 px-3 xl:px-5 text-start text-11 sm:text-xs lg:text-sm xl:text-base font-normal whitespace-nowrap  text-white tracking-0.16"> {(user.lastLoginAt > 0) ? formatDateTime(user.lastLoginAt, "DD-MMS-YYYY hh:mm AMPM") : <span></span>} </td>
                                                    </tr>
                                                ))
                                            )}
                                </tbody>
                            </table>
                        )}
            </div>
            <div className="flex items-center justify-end gap-2.5 pt-3.5 sm:pt-5">
                <p className="text-white font-normal text-10 sm:text-sm">Page</p>
                <div className="relative">
                    <button
                        className="border bg-grey-jaguar border-grey-tuna rounded-md py-1 px-2 text-10 sm:text-sm cursor-pointer text-white mt-1"
                        onClick={toggleUserPageSizeDropdown}>
                        {pageSize ?? "10"}
                        <svg
                            className="h-5 w-5 ml-2 inline-block"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor">
                            <path
                                fillRule="evenodd"
                                d="M10 12a1 1 0 01-.707-.293l-4-4a1 1 0 111.414-1.414L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4A1 1 0 0110 12z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                    {isUserPageSizeOpen && (
                        <div className="absolute w-full text-white bg-black-darkBlack border border-grey-tuna rounded-md shadow-lg -top-28"
                            onMouseLeave={() => { toggleUserPageSizeDropdown() }} role="none">
                            {pageSizeOptions.map((option) => (
                                <button key={option} className="py-1.5 px-4 hover:bg-grey-tuna cursor-pointer w-full" onClick={() => handleUserPageSizeChange(option)}>
                                    {option}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
                <p className="text-white font-normal text-10 sm:text-sm">of {recordCount}</p>
            </div>
            <Pagination
                currentPage={pageNumber}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
            <button
                onClick={() => closeUserDetailModal()}>
                <Modal
                    appElement={document.getElementById('root') as HTMLElement}
                    isOpen={isModalOpen}
                    style={{
                        overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            backdropFilter: 'blur(5px)',
                            zIndex: '99'
                        }
                    }}
                    contentLabel="Example Modal" className="w-full h-full flex justify-center items-center backtesting-modal">
                    <div style={{ display: isModalOpen ? 'block' : 'none' }} className="sm:mx-0 w-[300px] sm:w-[85%] md:w-[624px] relative z-[1] after:opacity-5 after:['*'] after:absolute after:z-[-1] after:top-2 after:left-1.5 after:w-[98%] after:h-[98%] after:bg-gradient after:blur-[2.5rem] before:['*'] before:absolute before:z-[-1] before:top-2 before:left-1.5 before:w-[98%] before:h-[98%] before:bg-gradient before:opacity-30 before:blur-[2.5rem]">
                        <div className="w-full lg:w-[624px] bg-[#000] border-4 border-bottom relative">
                            <div className="bg-popup-backtesting bg-no-repeat bg-right h-full" style={{ backgroundSize: '80%' }}>
                                <div className="px-4 py-[16px] sm:p-7 2xl:p-12 w-full">
                                    <div className="block sm:flex items-center justify-between">
                                        <div className="flex items-center">
                                            <div className="flex">
                                                <div className="w-14 h-14 sm:w-[82px] sm:h-[82px]">
                                                    {isNotNullUndefinedBlank(user?.profileImageUrl ?? "") ? (
                                                        <img src={user?.profileImageUrl ?? ""} className="rounded-full w-full h-full shadow-lg border-4 border-user-datail-radius object-cover" alt="user" />
                                                    ) : (
                                                        <img src={Images.userprofile} className="rounded-full w-full h-full shadow-lg border-4 border-user-datail-radius object-cover" alt="user" />)}
                                                </div>
                                            </div>
                                            <div className="ml-5">
                                                <p className="text-sm sm:text-lg font-semibold leading-normal text-white">{user?.username ?? ""}</p>
                                                <p className="text-10 sm:text-sm font-normal leading-normal text-grey-nobel mt-1.6">{user?.email ?? ""}</p>
                                            </div>
                                        </div>
                                        <div className="col-span-1 mt-5 sm:mt-0">
                                            <p className="text-xs sm:text-sm font-normal text-grey-suit ml-3.5">
                                                Account Risk
                                            </p>
                                            <p className="text-17 font-semibold text-white mt-2.5 flex items-center">
                                                <GaugeChart
                                                    style={chartStyle}
                                                    nrOfLevels={10}
                                                    colors={["#00AEEF", "#8DC63F"]}
                                                    arcWidth={0.1}
                                                    percent={gaugeValue}
                                                    textColor={"#444444"}
                                                    needleColor={"#00AEEF"}
                                                    needleBaseColor={"#00AEEF"}
                                                    hideText={true}
                                                    animate={false}
                                                />
                                                <span className="bg-[#7B6AFE] text-white text-10 py-0.5 px-1.5 rounded-full ml-2.5"> +2.83%</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 mt-7">
                                        <div className="col-span-1">
                                            <p className="text-xs lg:text-sm font-normal text-grey-suit">
                                                Total Profit
                                            </p>
                                            <p className="text-sm lg:text-17 font-semibold text-white mt-1 sm:mt-2.5">
                                                ${formatNumberWithCommas(user?.profit?.totalProfit ?? "0.00")}
                                            </p>
                                        </div>
                                        <div className="col-span-1">
                                            <p className="text-xs lg:text-sm font-normal text-grey-suit">
                                                Account Age
                                            </p>
                                            <p className="text-sm lg:text-17 font-semibold text-white mt-1 sm:mt-2.5">
                                                {user?.accountAge ?? "1D"}
                                            </p>
                                        </div>
                                        <div className="col-span-1">
                                            <p className="text-xs lg:text-sm font-normal text-grey-suit">
                                                Floating P/L
                                            </p>
                                            <p className="text-sm lg:text-17 font-semibold text-white mt-1 sm:mt-2.5">
                                                ${formatNumberWithCommas(user?.profit?.floatingPnl ?? "0.00")}
                                            </p>
                                        </div>
                                        <div className="col-span-1">
                                            <p className="text-xs lg:text-sm font-normal text-grey-suit">
                                                Future Balance
                                            </p>
                                            <p className="text-sm lg:text-17 font-semibold text-white mt-1 sm:mt-2.5">
                                                ${formatNumberWithCommas(user?.balance?.futureWalletBalance ?? "0.00")}
                                            </p>
                                        </div>
                                        <div className="col-span-1">
                                            <p className="text-xs lg:text-sm font-normal text-grey-suit">
                                                Available Fuel
                                            </p>
                                            <p className="text-sm lg:text-17 font-semibold text-white mt-1 sm:mt-2.5">
                                                ${formatNumberWithCommas(user?.balance?.availableFuel ?? "0.00")}
                                            </p>
                                        </div>
                                        <div className="col-span-1">
                                            <p className="text-xs lg:text-sm font-normal text-grey-suit">
                                                StopLoss Count
                                            </p>
                                            <p className="text-sm lg:text-17 font-semibold text-white mt-1 sm:mt-2.5">
                                                {user?.profit?.stopLossCount ?? 0}
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            type="button"
                                            disabled={loading}
                                            className={`relative w-full flex items-center justify-center py-2 sm:py-3.5 cursor-pointer font-bold text-xs sm:text-sm transition-all text-white mt-4 sm:mt-7 btn-hover-animation focus-visible:bg-[right_center] hover:bg-[right_center] outline-none ${loading ? 'bg-gradient-hover opacity-60' : 'bg-gradient-hover'}`}
                                            onClick={onAccessAccount}>
                                            {loading ? (
                                                <p className='flex items-center'><span className='w-4 h-4 border-2 border-dashed animate-spin rounded-full mr-2'>{''}</span>Applying...</p>
                                            ) : (
                                                'ACCESS ACCOUNT'
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </button>
        </div>
    );
};

export default User;